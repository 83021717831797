<template>
  <div class="bread-cumb-area">
    <nav
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li
          v-for="(item, index) in breadCrumbItems"
          :key="index"
          :class="{'active': (index == breadCrumbItems.length-1)}"
          class="breadcrumb-item"
        >
          <router-link
            v-if="item.isLink"
            :to="{name: item.routeName}"
          >{{item.routeLabel}}</router-link>
          <template v-else>{{item.routeLabel}}</template>
          
        </li>
      </ol>
    </nav>

    <nav
      v-if="isLeftDropDown"
      class="navbar navbar-expand-lg"
    >
      <div class="container-fluid">
        <a
          class="navbar-brand"
          href="#"
        >Navbar</a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDarkDropdown"
          aria-controls="navbarNavDarkDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse"
          id="navbarNavDarkDropdown"
        >
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDarkDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >

              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDarkDropdownMenuLink"
              >
                <li><a
                    class="dropdown-item"
                    href="#"
                  >Action</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>

  </div>
</template>
<script>
export default {
  props: {
    isLeftDropDown: {
      type: Boolean,
      default: false,
    },
    breadCrumbItems: {
      type: Array,
      default: () => [
        {
          routeName: "Dashboard",
          routeLabel: "Dashboard",
          isLink: true,
        },
        {
          routeName: "AddTenderInfo",
          routeLabel: "Add Tender Info",
          isLink: true,
        },
      ],
    },
  },
};
</script>
<style lang="scss" scoped>
nav {
  margin: 0px;
  padding: 0px;
}
.breadcrumb {
  margin: 0px;
  padding: 10px 0px;
  a {
    color: rgba(45, 55, 72, 0.58);
  }
  .active {
    color: #bf1616;
  }
}
.bread-cumb-area {
  display: flex;
  justify-content: space-between;
}
</style>