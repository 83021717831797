import instanceAxios from '@/utility/axios.js'

// initial state
const state = () => ({
    isLoading: false,
    securityReceiveApproval: null,
    securityReceiveApprovalPagination: null,
    performanceSecurityApproval: null,
    performanceSecurityApprovalPagination: null,
    bankGuaranteeApproval: null,
    bankGuaranteeApprovalPagination: null,
})

// getters
const getters = {
    isLoading: state => state.isLoading,
    securityReceiveApproval: state => state.securityReceiveApproval,
    securityReceiveApprovalPagination: state => state.securityReceiveApprovalPagination,
    performanceSecurityApproval: state => state.performanceSecurityApproval,
    performanceSecurityApprovalPagination: state => state.performanceSecurityApprovalPagination,
    bankGuaranteeApproval: state => state.bankGuaranteeApproval,
    bankGuaranteeApprovalPagination: state => state.bankGuaranteeApprovalPagination,
};

// mutations
const mutations = {
    IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    SECURITY_RECEIVE_APPROVAL(state, securityReceiveApproval) {
        state.securityReceiveApproval = securityReceiveApproval
    },
    SECURITY_RECEIVE_APPROVAL_PAGINATION(state, securityReceiveApprovalPagination) {
        state.securityReceiveApprovalPagination = securityReceiveApprovalPagination
    },
    PERFORMANCE_SECURITY_APPROVAL(state, performanceSecurityApproval) {
        state.performanceSecurityApproval = performanceSecurityApproval
    },
    PERFORMANCE_SECURITY_APPROVAL_PAGINATION(state, performanceSecurityApprovalPagination) {
        state.performanceSecurityApprovalPagination = performanceSecurityApprovalPagination
    },
    BANK_GUARANTEE_APPROVAL(state, bankGuaranteeApproval) {
        state.bankGuaranteeApproval = bankGuaranteeApproval
    },
    BANK_GUARANTEE_APPROVAL_PAGINATION(state, bankGuaranteeApprovalPagination) {
        state.bankGuaranteeApprovalPagination = bankGuaranteeApprovalPagination
    }
}

// actions
const actions = {
    fetchSecurityReceiveApproval: ({ commit }, query = null) => {
        commit('IS_LOADING', true);
        let page = '';
        let filter_name = '';
        let filter_value = '';
        let date_between_name = '';
        let date_start = '';
        let date_end = '';

        let search = '';
        let pageLength = 10;
        let concern_id = 0;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;

            filter_name = query.filter_name;
            filter_value = query.filter_value;

            date_between_name = query.date_between_name;
            date_start = query.date_start;
            date_end = query.date_end;
            concern_id = query.concern_id;

        }
        let url = `approval/security-receival?pageLength=${pageLength}&concern_id=${concern_id}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }
        if (filter_name !== null && filter_name !== "" && filter_value !== null && filter_value !== "") {
            url = `${url}&filter_name=${filter_name}&filter_value=${filter_value}`
        }

        if (date_between_name !== null && date_between_name !== "" && date_start !== null && date_start !== "" && date_end !== null && date_end !== "") {
            url = `${url}&date_between_name=${date_between_name}&date_start=${date_start}&date_end=${date_end}`
        }

        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                const items = res.data.data;
                commit('SECURITY_RECEIVE_APPROVAL', items);
                const pagination = {
                    total: res.data.total,  // total number of elements or items
                    per_page: res.data.per_page, // items per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('SECURITY_RECEIVE_APPROVAL_PAGINATION', pagination);
                commit('IS_LOADING', false);
                resolve(200);
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(422);
            })
        });
    },
    fetchPerformanceSecurityApproval: ({ commit }, query = null) => {
        commit('IS_LOADING', true);
        let filter_name = '';
        let filter_value = '';
        let date_between_name = '';
        let date_start = '';
        let date_end = '';
        let page = '';
        let search = '';
        let pageLength = 10;
        let concern_id = 0;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;

            filter_name = query.filter_name;
            filter_value = query.filter_value;

            date_between_name = query.date_between_name;
            date_start = query.date_start;
            date_end = query.date_end;
            concern_id = query.concern_id;
        }
        let url = `approval/performance-security-approval?pageLength=${pageLength}&concern_id=${concern_id}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }

        if (filter_name !== null && filter_name !== "" && filter_value !== null && filter_value !== "") {
            url = `${url}&filter_name=${filter_name}&filter_value=${filter_value}`
        }

        if (date_between_name !== null && date_between_name !== "" && date_start !== null && date_start !== "" && date_end !== null && date_end !== "") {
            url = `${url}&date_between_name=${date_between_name}&date_start=${date_start}&date_end=${date_end}`
        }
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                const items = res.data.data;
                commit('PERFORMANCE_SECURITY_APPROVAL', items);
                const pagination = {
                    total: res.data.total,  // total number of elements or items
                    per_page: res.data.per_page, // items per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('PERFORMANCE_SECURITY_APPROVAL_PAGINATION', pagination);
                commit('IS_LOADING', false);
                resolve(200);
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(422);
            })
        });
    },
    fetchBankGuaranteeApproval: ({ commit }, query = null) => {
        commit('IS_LOADING', true);
        let filter_name = '';
        let filter_value = '';
        let date_between_name = '';
        let date_start = '';
        let date_end = '';
        let page = '';
        let search = '';
        let pageLength = 10;
        let concern_id = 0;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;

            filter_name = query.filter_name;
            filter_value = query.filter_value;

            date_between_name = query.date_between_name;
            date_start = query.date_start;
            date_end = query.date_end;
            concern_id = query.concern_id;

        }
        let url = `approval/bank-guarantee-approval?pageLength=${pageLength}&concern_id=${concern_id}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }
        if (filter_name !== null && filter_name !== "" && filter_value !== null && filter_value !== "") {
            url = `${url}&filter_name=${filter_name}&filter_value=${filter_value}`
        }
        if (date_between_name !== null && date_between_name !== "" && date_start !== null && date_start !== "" && date_end !== null && date_end !== "") {
            url = `${url}&date_between_name=${date_between_name}&date_start=${date_start}&date_end=${date_end}`
        }
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                const items = res.data.data;
                commit('BANK_GUARANTEE_APPROVAL', items);
                const pagination = {
                    total: res.data.total,  // total number of elements or items
                    per_page: res.data.per_page, // items per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('BANK_GUARANTEE_APPROVAL_PAGINATION', pagination);
                commit('IS_LOADING', false);
                resolve(200);
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(422);
            })
        });
    },
    updateSecurityRecieveApprovalStatus: ({ commit }, item) => {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`approval/update-security-receival-status/${item.id}`).then(res => {
                const items = res.data.payload.data;
                commit('IS_LOADING', false);
                resolve(items);
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(422);
            })
        });
    },
    updatePerformanceSecurityStatus: ({ commit }, item) => {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`approval/update-performance-security-status/${item.id}`).then(res => {
                const items = res.data.payload.data;
                commit('IS_LOADING', false);
                resolve(items);
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(422);
            })
        });
    },
    updateBankGuaranteeStatus: ({ commit }, item) => {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`approval/update-bank-guarantee-status/${item.id}`).then(res => {
                const items = res.data.payload.data;
                commit('IS_LOADING', false);
                resolve(items);
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(422);
            })
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}