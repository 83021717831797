<template>
    <div>
        <div v-if="!isLoading">
            <div class="main-area">
                <div class="align-items-center d-flex header-top-area justify-content-center">
                    <!-- Left navbar links -->
                    <div class="left-nav">
                        <ul class="list-unstyled m-0 p-0">
                            <li class="d-inline-block">
                                <a @click.prevent="showHideMobileMenu" class="text-decoration-none" href="">
                                    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512">
                                        <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                        <path
                                            d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM64 256C64 238.3 78.33 224 96 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H96C78.33 288 64 273.7 64 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z" />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- Right navbar links -->
                    <div class="right-nav">
                        <ul class="list-unstyled m-0 p-0">
                            <!-- <li class="d-inline-block">
                                <div class="bell-area">
                                    <a class="text-decoration-none" href="">
                                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path
                                                d="M256 32V49.88C328.5 61.39 384 124.2 384 200V233.4C384 278.8 399.5 322.9 427.8 358.4L442.7 377C448.5 384.2 449.6 394.1 445.6 402.4C441.6 410.7 433.2 416 424 416H24C14.77 416 6.365 410.7 2.369 402.4C-1.628 394.1-.504 384.2 5.26 377L20.17 358.4C48.54 322.9 64 278.8 64 233.4V200C64 124.2 119.5 61.39 192 49.88V32C192 14.33 206.3 0 224 0C241.7 0 256 14.33 256 32V32zM216 96C158.6 96 112 142.6 112 200V233.4C112 281.3 98.12 328 72.31 368H375.7C349.9 328 336 281.3 336 233.4V200C336 142.6 289.4 96 232 96H216zM288 448C288 464.1 281.3 481.3 269.3 493.3C257.3 505.3 240.1 512 224 512C207 512 190.7 505.3 178.7 493.3C166.7 481.3 160 464.1 160 448H288z" />
                                        </svg>
                                    </a>
                                </div>
                            </li> -->
                            <li class="d-inline-block">
                                <div class="avator-area">
                                    <div class="avator">
                                        <img :src="setProfilePicture(getProfileDetails.profile_path)" alt="">
                                    </div>
                                </div>
                            </li>
                            <li class="d-inline-block">
                                <div @click="profile_submenu = !profile_submenu" class="profile-top-area">
                                    <div class="profile-top">
                                        <h5 v-html="getProfileDetails.name"></h5>
                                        <span>{{ this.$string_limit(getProfileDetails.email, 15) }}</span>
                                    </div>
                                    <div v-if="profile_submenu" class="profile-sub-menu">
                                        <div @click="this.$router.push({ name: 'userProfile' })"
                                            class="profile-sub-menu-item">
                                            Profile</div>
                                        <div @click="logout" class="profile-sub-menu-item">Logout</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- /.header-top-area -->
            <div class="main-sidebar-area" :style="styleObject">
                <div class="main-sidebar ">
                    <div class="site-logo mb-4 sidebar-padding">
                        <a class="cursor-pointer" @click="this.$router.push({ name: 'Dashboard' })">
                            <img :src="site_logo" alt="site-logo">
                        </a>
                    </div>
                    <div class="sidebar">
                        <ul class="m-0 p-0">
                            <li>
                                <router-link @click="menuClick" :to="{ name: 'Dashboard' }">
                                    <img :src="dashboard_icon" alt="Dashboard">
                                    Dashboard
                                </router-link>
                            </li>
                            <li v-if="getPermission('tender_information.store')
                                || getPermission('tender_information.index')
                                || getPermission('tender_submission.store')
                                || getPermission('tender_submission.index')
                                || getPermission('award_and_executions.store')
                                || getPermission('award_and_executions.index')
                            ">
                                <a href="#">
                                    <img :src="add_tender_info_icon" alt="Add Tender Info">
                                    Tender Info
                                    <SvgIcon></SvgIcon>
                                </a>
                                <ul class="nav_dropdown">
                                    <li v-if="getPermission('tender_information.store')">
                                        <router-link @click="menuClick" :to="{ name: 'AddTenderInfo' }">
                                            Add Tender info
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('tender_information.index')">
                                        <router-link @click="menuClick" :to="{ name: 'tenderList' }">
                                            Tender List
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('tender_submission.store')">
                                        <router-link @click="menuClick" :to="{ name: 'add_tender_submission' }">
                                            Tender Submission
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('tender_submission.index')">
                                        <router-link @click="menuClick" :to="{ name: 'list_tender_submission' }">
                                            Tender Submission List
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('award_and_executions.store')">
                                        <router-link @click="menuClick" :to="{ name: 'add_award_and_execution' }">
                                            Award and execution
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('award_and_executions.index')">
                                        <router-link @click="menuClick" :to="{ name: 'list_award_and_execution' }">
                                            Award And Execution List
                                        </router-link>
                                    </li>

                                    <!-- <li>
                                    <router-link  @click="menuClick" :to="{ name: 'TenderBilling' }">
                                        Tender Billing
                                    </router-link>
                                </li>
                                <li>
                                    <router-link  @click="menuClick" :to="{ name: 'TenderDetails' }">
                                        Tender Details
                                    </router-link>
                                </li> -->

                                </ul>
                            </li>
                            <li v-if="getPermission('tender_information.index')">
                                <router-link @click="menuClick" :to="{ name: 'tender_list_upcoming' }">
                                    <img :src="upcoming_icon" alt="tender_list_upcoming">
                                    Upcoming Tender
                                </router-link>
                            </li>

                            <li v-if="getPermission('bill_submission.store')
                                || getPermission('bill_submission.index')
                                || getPermission('bill_receive.index')
                                || getPermission('bill_receive.store')
                            ">
                                <a href="#">
                                    <img :src="`${require('@/assets/img/icon/bill.png')}`" alt="Bills">
                                    Bills
                                    <SvgIcon></SvgIcon>
                                </a>
                                <ul class="nav_dropdown">
                                    <li v-if="getPermission('bill_submission.store')">
                                        <router-link @click="menuClick" :to="{ name: 'bill_submission_add' }">
                                            Add bill Submission
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('bill_submission.index')">
                                        <router-link @click="menuClick" :to="{ name: 'bill_submission_list' }">
                                            bill Submission list
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('bill_receive.store')">
                                        <router-link @click="menuClick" :to="{ name: 'bill_receive_add' }">
                                            Add Bill receive
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('bill_receive.index')">
                                        <router-link @click="menuClick" :to="{ name: 'bill_receive_list' }">
                                            Bill receive list
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li v-if="getPermission('folders.index')">
                                <router-link @click="menuClick" :to="{ name: 'DocumentLibrary' }">
                                    <img :src="document_img" alt="DocumentLibrary">
                                    Document Library
                                </router-link>
                            </li>
                            <li
                                v-if="getPermission('security_money.report') || getPermission('failure.report') || getPermission('contact_award.report') || getPermission('performance_security_list.report') || getPermission('retention_money_list.report')">
                                <a href="#">
                                    <img :src="`${require('@/assets/img/icon/bill.png')}`" alt="Bills">
                                    Report
                                    <SvgIcon></SvgIcon>
                                </a>
                                <ul class="nav_dropdown">
                                    <li>
                                        <router-link @click="menuClick" :to="{ name: 'reports' }">
                                            Tender Details
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('contact_award.report')">
                                        <router-link @click="menuClick" :to="{ name: 'contact_award' }">
                                            Contract award
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('failure.report')">
                                        <router-link @click="menuClick" :to="{ name: 'failure' }">
                                            Failure
                                        </router-link>
                                    </li>
                                    <!-- <li v-if="getPermission('performance_security_list.report')">
                                        <router-link @click="menuClick" :to="{ name: 'PerformanceSecurity' }">
                                            Performance Security
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('security_money.report')">
                                        <router-link @click="menuClick" :to="{ name: 'security_money' }">
                                            Security Money
                                        </router-link>
                                    </li> -->
                                    <li v-if="getPermission('retention_money_list.report')">
                                        <router-link @click="menuClick" :to="{ name: 'retention_money' }">
                                            Retention Money
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li
                                v-if="getPermission('tender_information.is_approved') || getPermission('bill_receive.is_approved')">
                                <a href="#">
                                    <img :src="approval_img" alt="Approval">
                                    Approval
                                    <SvgIcon></SvgIcon>
                                </a>
                                <ul class="nav_dropdown">
                                    <li v-if="getPermission('tender_information.is_approved')">
                                        <router-link @click="menuClick" :to="{ name: 'approval_tender_list' }">
                                            Tender info
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('bill_receive.is_approved')">
                                        <router-link @click="menuClick" :to="{ name: 'approval_bill_receive' }">
                                            Bill receive
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('security_receive_approval.approval')">
                                        <router-link @click="menuClick" :to="{ name: 'approval_security_receival' }">
                                            Tender Security
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('performance_security_approval.approval')">
                                        <router-link @click="menuClick" :to="{ name: 'performance_security_receival' }">
                                            Prf. Security
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('bank_guarantee_approval.approval')">
                                        <router-link @click="menuClick" :to="{ name: 'bank_guarantee_receival' }">
                                            Advance Payment Guarantee
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li v-if="getPermission('activitylog.index')">
                                <router-link @click="menuClick" :to="{ name: 'UserActivityLog' }">
                                    <img :src="user_activity_log_img" alt="UserActivityLog">
                                    User Activity Log
                                </router-link>
                            </li>
                            <li v-if="getPermission('lot.index')
                                || getPermission('jv_partners.index')
                                || getPermission('concern.index')
                                || getPermission('bank.index')
                                || getPermission('department.index')
                                || getPermission('procuring.index')
                                || getPermission('procuring_method.index')
                                || getPermission('project_name.index')
                                || getPermission('tender_type.index')
                                || getPermission('validity.index')
                                || getPermission('made_of_payment.index')
                            ">
                                <a href="#">
                                    <img :src="settings_icon" alt="general setting">
                                    General Settings
                                    <SvgIcon></SvgIcon>
                                </a>
                                <ul class="nav_dropdown">
                                    <li v-if="getPermission('currency.index')">
                                        <router-link @click="menuClick" :to="{ name: 'currency' }">
                                            Currency
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('jv_partners.index')">
                                        <router-link @click="menuClick" :to="{ name: 'jv_partner' }">
                                            JV Partners
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('concern.index')">
                                        <router-link @click="menuClick" :to="{ name: 'concern' }">
                                            Concern
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('lot.index')">
                                        <router-link @click="menuClick" :to="{ name: 'lot' }">
                                            Lot
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('bank.index')">
                                        <router-link @click="menuClick" :to="{ name: 'bank' }">
                                            Bank
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('department.index')">
                                        <router-link @click="menuClick" :to="{ name: 'department' }">
                                            Department
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('procuring.index')">
                                        <router-link @click="menuClick" :to="{ name: 'procuring' }">
                                            Procuring entity
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('procuring_method.index')">
                                        <router-link @click="menuClick" :to="{ name: 'procuring_method' }">
                                            Procuring Method
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('tender_type.index')">
                                        <router-link @click="menuClick" :to="{ name: 'tender_type' }">
                                            Tender Type
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('validity.index')">
                                        <router-link @click="menuClick" :to="{ name: 'validity' }">
                                            Validity
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('made_of_payment.index')">
                                        <router-link @click="menuClick" :to="{ name: 'made_of_payment' }">
                                            Mode of payment
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li v-if="
                            getPermission('roles.index') 
                            || getPermission('users.index')
                            || getPermission('db_backup')
                            ">
                                <a href="#">
                                    <img :src="settings_icon" alt="setting">
                                    Settings
                                    <SvgIcon></SvgIcon>
                                </a>
                                <ul class="nav_dropdown">
                                    <li v-if="getPermission('roles.index')">
                                        <router-link @click="menuClick" :to="{ name: 'role_list' }">
                                            Role Permission
                                        </router-link>
                                    </li>
                                </ul>
                                <ul class="nav_dropdown">
                                    <li v-if="getPermission('users.index')">
                                        <router-link @click="menuClick" :to="{ name: 'user_list' }">
                                            User
                                        </router-link>
                                    </li>
                                    <li v-if="getPermission('db_backup')">
                                        <router-link @click="menuClick" :to="{ name: 'backup' }">
                                            Back up
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <!-- /.sidebar -->
                </div>
                <!-- /.main-sidebar -->
            </div>
            <!-- /.main-sidebar -->
            <div class="main-content-area mt-4 mb-4">
                <div class="main-content">
                    <!-- Main content -->
                    <slot></slot>
                </div>
            </div>
            <!-- /.main-content-area -->
            <div class="main-footer">

            </div>
            <!-- /.main-footer -->
            <div :class="{ shado: shadoActive }" @click.prevent="hideShado"></div>
            <!-- /.shado -->
        </div>
        <!-- spinner loading -->
        <div v-else class="text-center mt-5 mb-5">
            <div class="spinner-grow  text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";
import { mapGetters, mapActions } from "vuex";
import { isPermission } from '@/utility/common.js'

export default {
    components: {
        SvgIcon,
    },
    computed: {
        ...mapGetters({
            getProfileDetails: "user/profileDetails",
            list: "permissions/listWithoutPagination",
            isLoading: "permissions/isLoading",
        })
    },
    data() {
        return {
            permision: null,
            profile_submenu: false,
            site_logo: `${require("@/assets/img/login/logo/logo-white.png")}`,
            dashboard_icon: `${require("@/assets/img/icon/dashboard.png")}`,
            add_tender_info_icon: `${require("@/assets/img/icon/add-tender-info.png")}`,
            tender_list_icon: `${require("@/assets/img/icon/tender-list.png")}`,
            upcoming_icon: `${require("@/assets/img/icon/upcoming.png")}`,
            settings_icon: `${require("@/assets/img/icon/settings.png")}`,
            document_img: `${require("@/assets/img/icon/document.png")}`,
            profile_img: `${require("@/assets/img/icon/profile.png")}`,
            approval_img: `${require("@/assets/img/icon/approval.png")}`,
            user_activity_log_img: `${require("@/assets/img/icon/user-activity-log.png")}`,
            styleObject: {
                height: `${window.outerHeight}px`,
            },
            shadoActive: false,
            site_width: window.innerWidth,
            sub_menu_display: true,
            pathD:
                "M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z",
        };
    },
    methods: {
        ...mapActions({
            fetchWithoutPagiantionList: "permissions/fetchWithoutPagiantionList",
            profileDetails: "user/profileDetails"
        }),
        menuClick(e) {
            var sidebar = document.querySelector('.sidebar');
            var dBlock = sidebar.querySelector('.d-block');
            if (dBlock) {
                var firstAtagD = dBlock.closest('li').firstChild;
                if (firstAtagD) {
                    firstAtagD.querySelector('svg').style.transform = 'rotate(0deg)';
                }
                dBlock.classList.remove('d-block');
            }
            var nav_dropdown = e.target.closest('.nav_dropdown');
            if (nav_dropdown) {
                nav_dropdown.classList.add('d-block');
                var firstAtag = nav_dropdown.closest('li').firstChild;
                if (firstAtag) {
                    firstAtag.querySelector('svg').style.transform = 'rotate(90deg)';
                }
            }
        },
        logout() {
            this.$store
                .dispatch("auth/logOut")
                .then((status) => {
                    this.$toast.success("Successfully logout");
                    if (status == 200) {
                        this.$router.push({ name: "login" });
                    }
                })
                .catch((status) => {
                    if (status == 404) {
                        this.$toast.error("Something is wrong. Please try again.");
                    }
                });
        },
        showHideMobileMenu() {
            // open show heide
            if (this.site_width > 0 && this.site_width <= 807) {
                this.styleObject.left = "0px";
                this.shadoActive = true;
            }
        },
        hideShado() {
            // open show heide
            if (this.site_width > 0 && this.site_width <= 807) {
                if (this.shadoActive) {
                    this.styleObject.left = "-250px";
                    this.shadoActive = false;
                }
            }
        },
        getPermission(type) {
            return isPermission(this.list, type);
        },
        setProfilePicture(imagePath) {
            if (imagePath != null && imagePath.indexOf("uploads") != -1)
                return process.env.VUE_APP_URL + imagePath;
            else if (imagePath == null)
                return `${require("@/assets/img/avatar/dummy.png")}`;
            else
                return imagePath;
        }
    },
    created() {
        this.profileDetails().then(() => {
            this.avator_img = (this.getProfileDetails.profile_path != null) ? process.env.VUE_APP_URL + this.getProfileDetails.profile_path : this.avator_img;
            this.user_name = this.getProfileDetails.name;
        });
    }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/admin-layout.scss";
</style>