<template>
    <div class="custom-container">
        <div class="row g-0">
            <div class="col-md-6 order-md-2">
                <div class="login-content-area mb-4">
                    <div v-if="!getIsLoading" class="login-content">
                        <h1 class="mb-2">Login</h1>
                        <p>Enter Your Email and Password</p>
                        <hr>
                        <Form @submit="login" :validation-schema="schemaLogin">
                            <div class="mb-3">
                                <label for="email" class="form-label">Email <span class="text-danger">*</span></label>
                                <Field :class="{ 'form-control': true }" placeholder="Enter Email" name="email"
                                    type="email" v-model="user.email" autocomplete="off" />
                                <ErrorMessage class="text-danger form-text" name="email" />
                                <div class="form-text text-danger"
                                    v-if="validation_errors && validation_errors.payload && validation_errors.payload.email && validation_errors.payload.email">{{
                                            validation_errors.payload.email[0]
                                    }}</div>

                            </div>
                            <div class="mb-3">
                                <label for="password" class="form-label">Password <span
                                        class="text-danger">*</span></label>
                                <Field class="form-control" placeholder="Enter Password" type="password" name="password"
                                    v-model="user.password" />
                                <ErrorMessage class="text-danger form-text" name="password" />
                                <div class="form-text text-danger"
                                    v-if="validation_errors && validation_errors.payload && validation_errors.payload.password && validation_errors.payload.password[0]">{{
                                            validation_errors.payload.password[0]
                                    }}</div>

                                <div class="form-text text-danger" v-if="errors">{{ errors }}</div>

                            </div>
                            <div class="mb-3 form-check">
                                <input type="checkbox" class="form-check-input" id="remember-me">
                                <label class="form-check-label" for="remember-me">Remember Me</label>                                
                                <router-link :to="{name:'forget_password'}" class="float-end text-danger">Forgot Your Password?</router-link>
                            </div>
                            <button type="submit" class="btn">Login</button>

                        </Form>
                    </div>
                    <!-- spinner loading -->
                    <div v-if="getIsLoading" class="text-center mt-5 mb-5">
                        <div class="spinner-grow  text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-6 order-md-1">
                <div :style="loginBannerImage" class="login-banner-area">
                    <div class="shado"></div>
                    <div class="login-banner">
                        <div class="logo">
                            <img :src="mainLogo" alt="">
                        </div>
                        <div class="banner-content">
                            <div class="quoate mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path
                                        d="M96 224C84.72 224 74.05 226.3 64 229.9V224c0-35.3 28.7-64 64-64c17.67 0 32-14.33 32-32S145.7 96 128 96C57.42 96 0 153.4 0 224v96c0 53.02 42.98 96 96 96s96-42.98 96-96S149 224 96 224zM352 224c-11.28 0-21.95 2.305-32 5.879V224c0-35.3 28.7-64 64-64c17.67 0 32-14.33 32-32s-14.33-32-32-32c-70.58 0-128 57.42-128 128v96c0 53.02 42.98 96 96 96s96-42.98 96-96S405 224 352 224z" />
                                </svg>
                            </div>
                            <h1 class="text-uppercase">Tender</h1>
                            <h1 class="text-uppercase">Management System</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: "UserLogin",
    beforeCreate() {
        // Authentication true push dashboard
        if (this.isAuthenticated) {
            this.$router.push({ name: "Dashboard" });
        } else {
            this.$router.push({ name: "login" });
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const schemaLogin = yup.object({
            email: yup.string().required().email().label('Email'),
            password: yup.string().required().label('Password'),
        });
        return {
            user: {
                email: "",
                password: "",
            },
            schemaLogin,
            loginBannerImage: `background-image: url(${require("@/assets/img/login/bgc.png")}); background-repeat:no-repeat;background-size: cover;
    background-position: center center; width:100%; height:100vh`,
            mainLogo: `${require("@/assets/img/login/logo/logo.png")}`,
        };
    },
    methods: {
        ...mapActions({
            'loginRequest': 'auth/loginRequest',
        }),
        ...mapMutations({
            'set_validation' : 'auth/SET_VALIDATION',
            'set_errors' : 'auth/SET_ERRORS',
        }),
        login() {
            this.loginRequest(this.user).then((status) => {
                // login success push to dashboard
                if (status == 200) {
                    this.$swal.fire({
                        text: "Success, Successfully logged in.",
                        icon: "success",
                        position: "top-end",
                        timer: 1000,
                    });
                    this.$router.push({ name: "Dashboard" });
                }
            });
        },
    },
    computed: {
        ...mapGetters({
            'getIsLoading': 'auth/getIsLoading',
            'getValidationErrors': 'auth/getValidationErrors',
            'getErrors': 'auth/getErrors',
            'isAuthenticated': 'auth/isAuthenticated',
        }),
        // server side validaton
        validation_errors() {
            let errors = this.getValidationErrors;
            if (errors != "") {
                this.$toast.error(errors.message);
            }
            return errors;
        },

        // server side validaton
        errors() {
            let errors = this.getErrors;
            if (errors.code != 403 && errors.message) {
                this.$toast.error(errors.message);
            }
            return errors.message ? errors.message : false;
        },
    },
    created(){
        this.set_validation('');
        this.set_errors([]);
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/login.scss";
</style>