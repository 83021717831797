<template>
  <div>
    <svg
      style="float-right"
      :width="svgWidth"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      :viewBox="viewBox"
    >
      <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
      <path :d="pathD" />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    svgWidth: {
      type: Number,
      default: 11,
    },
    viewBox: {
      type: String,
      default: "0 0 320 512",
    },
    pathD:{
        type: String,
        default: "M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
    }
  },
};
</script>
<style lang="scss">
</style>