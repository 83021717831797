import instanceAxios from '@/utility/axios.js'

const state = {
    items: [],
    paginatedData: null,

    details: [],

    common: [],
    validation_errors: [],
    errors: null,

    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,

};
const getters = {
    list: state => state.items,
    paginatedData: state => state.paginatedData,

    details: state => state.details,
    tenderInfosById: state => state.tenderInfosById,

    common: state => state.common,

    validation_errors: state => state.validation_errors,
    errors: state => state.errors,

    isLoading: state => state.isLoading,
    isCreating: state => state.isCreating,
    isUpdating: state => state.isUpdating,
    isDeleting: state => state.isDeleting,

};
const mutations = {
    SET_ITEMS: (state, items) => {
        state.items = items
    },
    SET_PAGINATED: (state, paginatedData) => {
        state.paginatedData = paginatedData
    },
    SET_DETAIL: (state, details) => {
        state.details = details
    },
    SET_TENDER_INFOS_BY_ID: (state, tenderInfosById) => {
        state.tenderInfosById = tenderInfosById
    },
    COMMON: (state, item) => {
        return state.common = item;
    },
    SET_VALIDATION_ERRORS: (state, validation_errors) => {
        state.validation_errors = validation_errors;
    },
    SET_ERRORS: (state, errors) => {
        state.errors = errors;
    },
    IS_LOADING(state, isLoading) {
        state.isLoading = isLoading;
    },
    SET_CREATING(state, isCreating) {
        state.isCreating = isCreating;
    },
    SET_UPDATING(state, isUpdating) {
        state.isUpdating = isUpdating;
    },
    SET_DELETING(state, isDeleting) {
        state.isDeleting = isDeleting;
    }
};
const actions = {
    fetchItems: ({ commit }, query = null) => {
        commit('IS_LOADING', true);
        let page = '';
        let search = '';
        let pageLength = 10;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
        }
        let url = `roles?is_draft=0&pageLength=${pageLength}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                const items = res.data.data;
                commit('SET_ITEMS', items);
                const pagination = {
                    total: res.data.total,  // total number of elements or items
                    per_page: res.data.per_page, // items per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('SET_PAGINATED', pagination);
                commit('IS_LOADING', false);
                resolve(200);
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(422);
            })
        });
    },
    fetchDetail({ commit }, id) {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`roles/${id}`)
                .then(res => {
                    commit('SET_DETAIL', res.data.payload.data);
                    commit('IS_LOADING', false);
                    resolve(200);
                }).catch(err => {
                    commit('IS_LOADING', false);
                    console.log('error', err);
                    reject(422);
                });
        });
    },
    add: ({ commit }, items) => {
        commit('SET_CREATING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post('roles', items).then(response => {
                if (response.status == 200) {
                    commit('SET_CREATING', false);
                    resolve(200);
                }
            }).catch((err) => {
                if (err.response.status === 422) {
                    commit('SET_ERRORS', err.response.data);
                }
                commit('SET_CREATING', false);
                reject(422);
            })
        });
    },
    update: ({ commit }, items) => {
        commit('SET_UPDATING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post(`roles/${items.get('id')}`, items).then(response => {
                if (response.status == 200) {
                    commit('SET_UPDATING', false);
                    resolve(200);
                }
            }).catch(() => {
                commit('SET_UPDATING', false);
                reject(422);
            })
        });
    },
    delete: ({ commit }, id) => {
        commit('SET_DELETING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.delete(`roles/${id}`).then(response => {
                if (response.status == 200) {
                    commit('SET_DELETING', false);
                    resolve(200);
                }
            }).catch(() => {
                commit('SET_DELETING', false);
                reject(422);
            })
        });
    },
    fetchCommon: ({ commit }) => {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get('all-permissions').then(response => {
                if (response.status == 200) {
                    // set auth user state
                    commit('COMMON', response.data.payload.data);
                    commit('IS_LOADING', false);
                    resolve(200);
                }
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(422);
            })
        });
    },
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}