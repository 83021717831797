<template>
    <div>
        <canvas id="successVsFailure" style="position: relative; height:30vh; width:100%"></canvas>
    </div>
</template>
<script>
import Chart from "chart.js";
import * as chart from "@/utility/chart-data.js";
export default {
    props: {
        dataOne: {
            type: Array,
            default: () => [5, 15, 10, 7, 79, 82, 27, 14, 45, 10, 11],
        },
        dataTwo: {
            type: Array,
            default: () => [10, 20, 5, 2, 70, 35, 20, 20, 30, 15, 14],
        },
        labels: {
            type: Array,
            default: () => [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "November",
                "December",
            ],
        },
    },
    mounted() {
        const type = "bar";
        const datasets = [
            {
                label: "Success",
                data: this.dataOne,
                backgroundColor: "green",
                borderColor: "green",
                borderWidth: 3,
            },
            {
                label: "Failure",
                data: this.dataTwo,
                backgroundColor: "red",
                borderColor: "red",
                borderWidth: 3,
            },
        ];
        const successVsFailure = document.getElementById("successVsFailure");
        new Chart(successVsFailure, chart.configChart(type, this.labels, datasets));
    },
};
</script>
<style lang="scss">
</style>