import instanceAxios from '@/utility/axios.js'

// initial state
const state = () => ({
    list: null,
    paginatedData: null,
    isLoading: false,
})

// getters
const getters = {
    list: state => state.list,
    paginatedData: state => state.paginatedData,
    isLoading: state => state.isLoading,
};

// mutations
const mutations = {
    LIST: (state, list) => {
        state.list = list
    },
    PAGINATED: (state, paginatedData) => {
        state.paginatedData = paginatedData
    },
    IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
}

// actions
const actions = {
    fetchList({ commit }, query = null) {
        let page = '';
        let search = '';
        let pageLength = 10;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
        }
        commit('IS_LOADING', true);
        let url = `activity-log?pageLength=${pageLength}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }
        instanceAxios.get(url)
            .then(res => {
                const list = res.data.data;
                commit('LIST', list);
                const pagination = {
                    total: res.data.total,  // total number of elements or list
                    per_page: res.data.per_page, // list per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('PAGINATED', pagination);
                commit('IS_LOADING', false);
            }).catch(err => {
                console.log('error', err);
                commit('IS_LOADING', false);
            });
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}