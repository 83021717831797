
export function configChart(type, labels, datasets) {
    const allConfig = {
        type,
        data: {
            labels,
            datasets
        },
        options: {
            responsive: true,
            lineTension: 1,
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            padding: 25
                        }
                    }
                ]
            }
        }
    }
    return allConfig;
}
export const planetChartData = {
    type: "bar",
    data: {
        labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "November", "December"],
        datasets: [
            {
                label: "Local Tender",
                data: [5, 15, 10, 7, 79, 82, 27, 14, 45, 10, 11],
                backgroundColor: "#38BDF8",
                borderColor: "#38BDF8",
                borderWidth: 3
            },
            {
                label: "International Tender",
                data: [10, 20, 5, 2, 70, 35, 20, 20, 30, 15, 14],
                backgroundColor: "#6366F1",
                borderColor: "#6366F1",
                borderWidth: 3
            }
        ]
    },
    options: {
        responsive: true,
        lineTension: 1,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        padding: 25
                    }
                }
            ]
        }
    }
};
