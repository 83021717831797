<template>
    <div>
        <bread-crumb :breadCrumbItems="breadCrumbItems"></bread-crumb>
        <CardItem :cardHeader="'tender information'" :cardBodyStyle="{ 'padding': '0px' }">
            <div class="tender-details-area">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td>
                                    <h5 class="mb-2">Procuring Entity</h5>
                                    <p>Power Division ICT</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Project Name</h5>
                                    <p>Water Supply</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Tender Participation Department</h5>
                                    <p>System Engineering Ltd.</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Type</h5>
                                    <p>International</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="mb-2">Procuring Entity</h5>
                                    <p>Power Division ICT</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Project Name</h5>
                                    <p>Water Supply</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Tender Participation Department</h5>
                                    <p>System Engineering Ltd.</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Type</h5>
                                    <p>International</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="mb-2">Procuring Entity</h5>
                                    <p>Power Division ICT</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Project Name</h5>
                                    <p>Water Supply</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Tender Participation Department</h5>
                                    <p>System Engineering Ltd.</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Type</h5>
                                    <p>International</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="mb-2">Procuring Entity</h5>
                                    <p>Power Division ICT</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Project Name</h5>
                                    <p>Water Supply</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Tender Participation Department</h5>
                                    <p>System Engineering Ltd.</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Type</h5>
                                    <p>International</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4 class="mb-3 px-3">Schedule Document</h4>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td>
                                    <h5 class="mb-2">Procuring Entity</h5>
                                    <p>Power Division ICT</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Project Name</h5>
                                    <p>Water Supply</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Tender Participation Department</h5>
                                    <p>System Engineering Ltd.</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Type</h5>
                                    <p>International</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4 class="mb-3 px-3">Procuring Entity Details</h4>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td>
                                    <h5 class="mb-2">Procuring Entity</h5>
                                    <p>Power Division ICT</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Project Name</h5>
                                    <p>Water Supply</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Tender Participation Department</h5>
                                    <p>System Engineering Ltd.</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Type</h5>
                                    <p>International</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </CardItem>

        <CardItem :cardHeader="'tender submission'" :cardBodyStyle="{ 'padding': '0px' }">
            <div class="tender-details-area">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td>
                                    <h5 class="mb-2">Tender Id</h5>
                                    <p>RM1089</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Project List</h5>
                                    <p>Water Supply</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Procuring Entity</h5>
                                    <p>Power Division ICT</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Tender Status</h5>
                                    <p>Active</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="mb-2">Tender Opening Report Information</h5>
                                    <p>XYZ</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4 class="mb-3 px-3 text-capitalize">proposal Document</h4>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td>
                                    <h5 class="mb-2">Procuring Entity</h5>
                                    <p>Power Division ICT</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Project Name</h5>
                                    <p>Water Supply</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Tender Participation Department</h5>
                                    <p>System Engineering Ltd.</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Type</h5>
                                    <p>International</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4 class="mb-3 px-3 text-capitalize">Tender Opening Information</h4>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td>
                                    <h5 class="mb-2">Procuring Entity</h5>
                                    <p>Power Division ICT</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Project Name</h5>
                                    <p>Water Supply</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Tender Participation Department</h5>
                                    <p>System Engineering Ltd.</p>
                                </td>
                                <td>
                                    <h5 class="mb-2">Type</h5>
                                    <p>International</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </CardItem>
    </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import CardItem from "@/components/CardItem.vue";
export default {
    components: {
        BreadCrumb,
        CardItem,
    },
    data() {
        return {
            test: this.$globalVariable,
            breadCrumbItems: [
                {
                    routeName: "Dashboard",
                    routeLabel: "Dashboard ",
                },
                {
                    routeName: "AddTenderInfo",
                    routeLabel: "AddTenderInfo",
                },
            ],
        };
    },
};
</script>
<style lang="scss" scoped>
.tender-details-area {
    font-family: $ff_Inter;

    h5 {
        text-transform: capitalize;
        color: #2d3748;
    }

    p {
        text-transform: capitalize;
        color: #73808d;
    }

    td {
        padding: 15px 0px 15px 15px;
    }
}
</style>