import instanceAxios from '@/utility/axios'

const state = {
    access_token: localStorage.getItem('access_token') || null,
    authUser: [],
    validation: '',
    resetValidation: '',
    errors: [],

    isLoading: false,
};
const getters = {
    getToken: (state) => {
        return state.access_token;
    },
    getAuthUser: (state) => {
        return state.authUser;
    },
    isAuthenticated: (state) => {
        return (state.access_token != null) ? true : false;
    },
    getValidationErrors: (state) => {
        return state.validation;
    },
    getResetValidationErrors: (state) => {
        return state.resetValidation;
    },
    getErrors: (state) => {
        return state.errors;
    },
    getIsLoading: state => state.isLoading,

};
const mutations = {
    ACCESS_TOKEN: (state, token) => {
        return state.access_token = token;
    },
    AUTH_USER: (state, item) => {
        return state.authUser = item;
    },
    SET_LOGOUT: (state) => {
        state.authUser = null;
        state.access_token = null;
    },
    SET_VALIDATION(state, items) {
        state.validation = items;
    },
    SET_RESET_VALIDATION(state, items) {
        state.resetValidation = items;
    },
    SET_ERRORS(state, items) {
        state.errors = items;
    },
    SET_IS_LOADING(state, data) {
        state.isLoading = data;
    }
};
const actions = {
    logOut: ({ commit }) => {
        commit("SET_IS_LOADING", true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post('logout').then(response => {
                if (response.status == 200) {
                    localStorage.removeItem('access_token');
                    // set auth user null
                    commit('ACCESS_TOKEN', null);
                    commit('AUTH_USER', []);
                    commit("SET_IS_LOADING", false);
                    resolve(200);
                }
            }).catch(e => {
                // all errors execpt validation
                commit("SET_IS_LOADING", false);
                commit('SET_ERRORS', e.response.data);
                reject(404);
            })
        });

    },
    loginRequest: ({ commit }, user) => {
        commit("SET_IS_LOADING", true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post('login', {
                email: user.email,
                password: user.password,
            }).then(response => {
                if (response.status == 200) {
                    commit("SET_IS_LOADING", false);
                    // set access token to local storage
                    localStorage.setItem('access_token', response.data.payload.access_token);
                    // set auth user state
                    commit('ACCESS_TOKEN', response.data.payload.access_token);
                    commit('AUTH_USER', response.data.payload.data);

                    resolve(200);
                }
            }).catch(e => {
                // Only validation errors
                if (e.response.status === 422) {
                    commit('SET_VALIDATION', e.response.data);
                } else {
                    // all errors execpt validation
                    commit('SET_ERRORS', e.response.data);
                }
                commit("SET_IS_LOADING", false);
                reject(422);
            })
        });
    },
    resetPasswordRequest: ({ commit }, user) => {
        commit("SET_IS_LOADING", true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post('reset-password', {
                email: user.email,
            }).then(response => {
                if (response.status == 200) {
                    commit("SET_IS_LOADING", false);                    

                    resolve(200);
                }
            }).catch(e => {
                // Only validation errors
                if (e.response.status === 422) {
                    commit('SET_RESET_VALIDATION', e.response.data);
                } else {
                    // all errors execpt validation
                    commit('SET_ERRORS', e.response.data);
                }
                commit("SET_IS_LOADING", false);
                reject(422);
            })
        });
    },
    passwordVerifyRequest: ({ commit }, user) => {
        commit("SET_IS_LOADING", true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post('verify-password', {
                token: user.token,
                password: user.password,
                password_confirmation: user.password_confirmation,
            }).then(response => {
                if (response.status == 200) {
                    commit("SET_IS_LOADING", false);                    

                    resolve(200);
                }
            }).catch(e => {
                console.log(e);
                // Only validation errors
                if (e.response.status === 422) {
                    commit('SET_RESET_VALIDATION', e.response.data);
                } else {
                    // all errors execpt validation
                    commit('SET_ERRORS', e.response.data);
                }
                commit("SET_IS_LOADING", false);
                reject(422);
            })
        });
    }

};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}