import { createStore } from 'vuex'

import auth from "@/store/modules/auth"
import temp_file from "@/store/modules/temp_file"
import general_setting_lot from "@/store/modules/general-settings/lot"
import general_setting_bank from "@/store/modules/general-settings/bank"
import general_setting_department from "@/store/modules/general-settings/department"
import general_setting_procuring_method from "@/store/modules/general-settings/procuring_method"
import general_setting_procuring from "@/store/modules/general-settings/procuring"
import general_setting_project from "@/store/modules/general-settings/project"
import general_setting_tender_type from "@/store/modules/general-settings/tender_type"
import general_setting_validitie from "@/store/modules/general-settings/validitie"
import general_setting_made_of_payment from "@/store/modules/general-settings/made_of_payment"
import add_tender_info from "@/store/modules/add_tender_info"
import tender_submission from "@/store/modules/tender_submission"
import award_and_execution from "@/store/modules/award_and_execution";
import bill_submission from "@/store/modules/bill_submission";
import bill_receive from "@/store/modules/bill_receive";
import reports from "@/store/modules/reports";
import user from "@/store/modules/user";
import activity_log from "@/store/modules/activity_log";
import common from "@/store/modules/common";
import permissions from '@/store/modules/permissions'
import role from '@/store/modules/role'
import dashboard from '@/store/modules/dashboard'
import approval from '@/store/modules/approval.js'
import settings from '@/store/modules/settings.js'
import general_setting_concern from '@/store/modules/general-settings/concern.js'
import general_setting_jv_partner from '@/store/modules/general-settings/jv_partner.js'
import general_setting_currency from '@/store/modules/general-settings/currency.js'

export default createStore({
    modules: {
        auth,
        add_tender_info,
        temp_file,
        general_setting_lot,
        general_setting_bank,
        general_setting_department,
        general_setting_procuring_method,
        general_setting_procuring,
        general_setting_project,
        general_setting_tender_type,
        general_setting_validitie,
        general_setting_made_of_payment,
        tender_submission,
        award_and_execution,
        bill_submission,
        bill_receive,
        reports,
        user,
        activity_log,
        common,
        permissions,
        role,
        dashboard,
        approval,
        settings,
        general_setting_concern,
        general_setting_jv_partner,
        general_setting_currency
    }
})
