import instanceAxios from '@/utility/axios'

const state = {
    files_tender_information_files: [],
    files_tender_submission_files: [],
    files_award_and_execution_files: [],
    is_uplaod_start: false,
    uplaoded_progress: 0,

};
const getters = {
    getTenderInformatonFiles: (state) => {
        return state.files_tender_information_files;
    },
    getTenderSubmissionFiles: (state) => {
        return state.files_tender_submission_files;
    },
    getAwardAndExecutionFiles: (state) => {
        return state.files_award_and_execution_files;
    },
    getUploadedStatus: (state) => {
        return state.is_uplaod_start;
    },
    getUplaodedProgress: (state) => {
        return state.uplaoded_progress;
    }
};
const mutations = {
    SET_TENDER_INFO: (state, item) => {
        state.files_tender_information_files = item;
    },
    SET_TENDER_SUBMISSION: (state, item) => {
        state.files_tender_submission_files = item;
    },
    SET_AWARD_AND_EXECUTION: (state, item) => {
        state.files_award_and_execution_files = item;
    },
    DELETE_TENDER_INFO: (state, item) => {
        var index = state.files_tender_information_files.findIndex(t => t.id == item);
        state.files_tender_information_files.splice(index, 1);
    },
    DELETE_TENDER_SUBMISSION: (state, item) => {
        var index = state.files_tender_submission_files.findIndex(t => t.id == item);
        state.files_tender_submission_files.splice(index, 1);
    },
    DELETE_AWARD_AND_EXECUTION: (state, item) => {
        var index = state.files_award_and_execution_files.findIndex(t => t.id == item);
        state.files_award_and_execution_files.splice(index, 1);
    },
    SET_UPLOADED_STATUS: (state, item) => {
        state.is_uplaod_start = item;
    },
    SET_UPLOADED_PROGRESS: (state, item) => {
        state.uplaoded_progress = item;
    },
    ADD_TENDER_INFO: (state, item) => {
        state.files_tender_information_files.unshift(item);
    },
    ADD_TENDER_SUBMISSION_INFO: (state, item) => {
        state.files_tender_submission_files.unshift(item);
    },
    ADD_AWARD_AND_EXECUTION: (state, item) => {
        state.files_award_and_execution_files.unshift(item);
    }
};
const actions = {
    // all temp file get
    tempFileRequest: ({ commit }, items) => {
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`settings/get-temp-files/${items.type}`).then(response => {
                if (response.status == 200) {
                    if (items.type == 'tender_information') {
                        commit('SET_TENDER_INFO', response.data.payload);
                    } else if (items.type == 'tender_submission') {
                        commit('SET_TENDER_SUBMISSION', response.data.payload);
                    } else if (items.type == 'award_and_execution') {
                        commit('SET_AWARD_AND_EXECUTION', response.data.payload);
                    }
                    resolve(200);
                }
            }).catch(() => {
                reject(422);
            })
        });
    },
    deleteFile: ({ commit }, items) => {
        return new Promise(function (resolve, reject) {
            instanceAxios.delete(`settings/delete-temp-file/${items.id}`).then(response => {
                if (response.status == 200) {
                    if (items.type == 'tender_information') {
                        commit('DELETE_TENDER_INFO', items.id);
                    } else if (items.type == 'tender_submission') {
                        commit('DELETE_TENDER_SUBMISSION', items.id);
                    } else if (items.type == 'award_and_execution') {
                        commit('DELETE_AWARD_AND_EXECUTION', items.id);
                    }
                    resolve(200);
                }
            }).catch(() => {
                reject(422);
            })
        });
    },
    uploadFile: ({ commit }, items) => {
        commit("SET_UPLOADED_STATUS", true);
        return new Promise(function (resolve, reject) {
            const config = {
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    commit("SET_UPLOADED_PROGRESS", percentCompleted);
                }
            };
            instanceAxios.post('settings/temp-file-upload', items, config).then(response => {
                if (response.status == 200) {
                    if (items.get('type') == 'tender_information') {
                        commit('ADD_TENDER_INFO', response.data.payload.data);
                    } else if (items.get('type') == 'tender_submission') {
                        commit('ADD_TENDER_SUBMISSION_INFO', response.data.payload.data);
                    } else if (items.get('type') == 'award_and_execution') {
                        commit('ADD_AWARD_AND_EXECUTION', response.data.payload.data);
                    }
                    commit("SET_UPLOADED_STATUS", false);
                    commit("SET_UPLOADED_PROGRESS", 0);
                    resolve(200);
                }
            }).catch(() => {
                commit("SET_UPLOADED_STATUS", false);
                commit("SET_UPLOADED_PROGRESS", 0);
                reject(422);
            })
        });
    },
    setEmpty: ({ commit }) => {
        commit("SET_TENDER_INFO", []);
    }

};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}