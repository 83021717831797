import instanceAxios from '@/utility/axios.js'

const state = {
    items: [],
    paginatedData: null,

    upcoming: [],
    upcomingPaginatedData: null,

    draftItems: [],
    paginatedDraftData: null,

    details: [],

    common: [],
    validation_errors: [],
    errors: [],

    isLoading: false,
    isDarftLoading: false,

    isCreating: false,
    isUpdating: false,
    isDeleting: false,

    isFileDeleting: false,

    isTaggingUser: false,
    isTaggDeleting: false,
    documents: null,
    isFileLoading: false,

    uplaoded_progress: 0,

    is_approved: false,


};
const getters = {
    list: state => state.items,
    paginatedData: state => state.paginatedData,

    draftList: state => state.draftItems,
    paginatedDraftData: state => state.paginatedDraftData,

    upcoming: state => state.upcoming,
    upcomingPaginatedData: state => state.upcomingPaginatedData,

    details: state => state.details,

    common: state => state.common,
    documents: state => state.documents,

    validation_errors: state => state.validation_errors,
    errors: state => state.errors,

    isLoading: state => state.isLoading,
    isDarftLoading: state => state.isDarftLoading,
    isCreating: state => state.isCreating,
    isUpdating: state => state.isUpdating,
    isDeleting: state => state.isDeleting,
    isFileDeleting: state => state.isFileDeleting,
    isTaggDeleting: state => state.isTaggDeleting,
    isFileLoading: state => state.isFileLoading,
    uplaoded_progress: state => state.uplaoded_progress,
    is_approved: state => state.is_approved,

};
const mutations = {
    SET_ITEMS: (state, items) => {
        state.items = items
    },
    SET_PAGINATED: (state, paginatedData) => {
        state.paginatedData = paginatedData
    },
    SET_DRAFT_ITEMS: (state, draftItems) => {
        state.draftItems = draftItems
    },
    SET_DRAFT_PAGINATED: (state, paginatedDraftData) => {
        state.paginatedDraftData = paginatedDraftData
    },
    SET_UPCOMING_ITEMS: (state, upcoming) => {
        state.upcoming = upcoming
    },
    SET_UPCOMING_PAGINATED: (state, upcomingPaginatedData) => {
        state.upcomingPaginatedData = upcomingPaginatedData
    },
    SET_DETAIL: (state, details) => {
        state.details = details
    },
    COMMON: (state, item) => {
        return state.common = item;
    },
    DOCUMENTS: (state, documents) => {
        return state.documents = documents;
    },
    UPLOAD_PROGRESS: (state, uplaoded_progress) => {
        return state.uplaoded_progress = uplaoded_progress;
    },
    SET_VALIDATION_ERRORS: (state, validation_errors) => {
        state.validation_errors = validation_errors;
    },
    SET_ERRORS: (state, errors) => {
        state.errors = errors;
    },
    IS_LOADING(state, isLoading) {
        state.isLoading = isLoading;
    },
    IS_FILE_LOADING(state, isFileLoading) {
        state.isFileLoading = isFileLoading;
    },
    IS_DRAFT_LOADING(state, isDarftLoading) {
        state.isDarftLoading = isDarftLoading;
    },
    SET_CREATING(state, isCreating) {
        state.isCreating = isCreating;
    },
    SET_UPDATING(state, isUpdating) {
        state.isUpdating = isUpdating;
    },
    SET_DELETING(state, isDeleting) {
        state.isDeleting = isDeleting;
    },
    SET_FILE_DELETING(state, isFileDeleting) {
        state.isFileDeleting = isFileDeleting;
    },
    SET_IS_TAGGED_USERS(state, isTaggingUser) {
        state.isTaggingUser = isTaggingUser;
    },
    SET_IS_TAGGED_DELETE(state, isTaggDeleting) {
        state.isTaggDeleting = isTaggDeleting;
    },
    IS_APPROVED(state, is_approved) {
        state.is_approved = is_approved;
    }
};
const actions = {
    fetchItems: ({ commit }, query = null) => {
        commit('IS_LOADING', true);
        let filter_name = '';
        let filter_value = '';
        let date_between_name = '';
        let date_start = '';
        let date_end = '';
        let page = '';
        let search = '';
        let pageLength = 10;
        let is_approved = '';
        let concern_id = 0;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
            is_approved = query.is_approved;

            filter_name = query.filter_name;
            filter_value = query.filter_value;
            date_between_name = query.date_between_name;
            date_start = query.date_start;
            date_end = query.date_end;
            concern_id = query.concern_id;
        }
        let url = `add-tender-info/tender-information?is_draft=0&pageLength=${pageLength}&concern_id=${concern_id}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }

        if (filter_name !== null && filter_name !== "" && filter_value !== null && filter_value !== "") {
            url = `${url}&filter_name=${filter_name}&filter_value=${filter_value}`
        }
        if (date_between_name !== null && date_between_name !== "" && date_start !== null && date_start !== "" && date_end !== null && date_end !== "") {
            url = `${url}&date_between_name=${date_between_name}&date_start=${date_start}&date_end=${date_end}`
        }

        if (is_approved !== null) {
            url = `${url}&is_approved=${is_approved}`;
        }

        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                const items = res.data.data;
                commit('SET_ITEMS', items);
                const pagination = {
                    total: res.data.total,  // total number of elements or items
                    per_page: res.data.per_page, // items per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('SET_PAGINATED', pagination);
                commit('IS_LOADING', false);
                resolve(200);
            }).catch((error) => {
                commit('IS_LOADING', false);
                reject(error.response);
            })
        });
    },
    fetchDraftItems: ({ commit }, query = null) => {
        commit('IS_DRAFT_LOADING', true);
        let page = '';
        let search = '';
        let pageLength = 10;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
        }
        let url = `add-tender-info/tender-information?is_draft=1&pageLength=${pageLength}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                const items = res.data.data;
                commit('SET_DRAFT_ITEMS', items);
                const pagination = {
                    total: res.data.total,  // total number of elements or items
                    per_page: res.data.per_page, // items per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('SET_DRAFT_PAGINATED', pagination);
                commit('IS_DRAFT_LOADING', false);
                resolve(200);
            }).catch((error) => {
                commit('IS_DRAFT_LOADING', false);
                reject(error.response);
            })
        });
    },
    fetchCommon: ({ commit }) => {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get('add-tender-info/common').then(response => {
                if (response.status == 200) {
                    // set auth user state
                    commit('COMMON', response.data.payload);
                    commit('IS_LOADING', false);
                    resolve(200);
                }
            }).catch((error) => {
                commit('IS_LOADING', false);
                reject(error.response);
            })
        });
    },
    fetchDetail({ commit }, id) {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`add-tender-info/tender-information/${id}`)
                .then(res => {
                    commit('SET_DETAIL', res.data.payload.data);
                    commit('IS_LOADING', false);
                    resolve(200);
                }).catch((error) => {
                    commit('IS_LOADING', false);

                    reject(error.response);
                });
        });
    },
    addTenderInfo: ({ commit }, items) => {
        commit('SET_CREATING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post('add-tender-info/tender-information', items).then(response => {
                if (response.status == 200) {
                    commit('SET_CREATING', false);
                    resolve(200);
                }
            }).catch((error) => {
                commit('SET_CREATING', false);
                reject(error.response);
            })
        });
    },
    updateTenderInfo: ({ commit }, items) => {
        commit('SET_UPDATING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post(`add-tender-info/tender-information/${items.get('id')}`, items).then(response => {
                if (response.status == 200) {
                    commit('SET_UPDATING', false);
                    resolve(200);
                }
            }).catch((error) => {
                commit('SET_UPDATING', false);
                reject(error.response);
            })
        });
    },

    deleteItem({ commit }, id) {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.delete(`add-tender-info/tender-information/${id}`)
                .then(() => {
                    commit('IS_LOADING', false);
                    resolve(200);
                }).catch((error) => {
                    commit('IS_LOADING', false);
                    reject(error.response);
                });
        });
    },
    // update file delete
    deleteUploadedFile: ({ commit }, items) => {
        commit("IS_FILE_LOADING", true);
        return new Promise(function (resolve, reject) {
            instanceAxios.delete(`add-tender-info/tender-information/delete-document/${items.id}`).then(response => {
                if (response.status == 200) {
                    commit("IS_FILE_LOADING", false);
                    resolve(200);
                }
            }).catch((error) => {
                commit("IS_FILE_LOADING", false);
                reject(error.response);
            })
        });
    },
    // update file delete
    uploadUpdateFile: ({ commit }, items) => {
        return new Promise(function (resolve, reject) {
            const config = {
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    commit("UPLOAD_PROGRESS", percentCompleted);
                }
            };
            instanceAxios.post(`add-tender-info/tender-information/add-document`, items, config).then(response => {
                if (response.status == 200) {
                    commit("UPLOAD_PROGRESS", 0);
                    resolve(200);
                }
            }).catch((error) => {
                commit("UPLOAD_PROGRESS", 0);
                reject(error.response);
            })
        });
    },
    taggedUsers: ({ commit }, items) => {
        commit("SET_IS_TAGGED_USERS", true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post(`add-tender-info/tender-information/tagg-users`, items).then(response => {
                if (response.status == 200) {
                    commit("SET_IS_TAGGED_USERS", false);
                    resolve(200);
                }
            }).catch((error) => {
                commit("SET_IS_TAGGED_USERS", false);
                reject(error.response);
            })
        });
    },
    taggedDelete: ({ commit }, id) => {
        commit("SET_IS_TAGGED_DELETE", true);
        return new Promise(function (resolve, reject) {
            instanceAxios.delete(`add-tender-info/tender-information/tagg-delete/${id}`).then(response => {
                if (response.status == 200) {
                    commit("SET_IS_TAGGED_DELETE", false);
                    resolve(200);
                }
            }).catch((error) => {
                commit("SET_IS_TAGGED_DELETE", false);
                reject(error.response);
            })
        });
    },
    upcomingTenderInfo: ({ commit }, query = null) => {
        commit('IS_LOADING', true);
        let page = '';
        let search = '';
        let pageLength = 10;
        let concern_id = 0;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
            concern_id = query.concern_id;
        }
        let url = `add-tender-info/tender-information?pageLength=${pageLength}&upcoming=1&concern_id=${concern_id}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                const items = res.data.data;
                commit('SET_UPCOMING_ITEMS', items);
                const pagination = {
                    total: res.data.total,  // total number of elements or items
                    per_page: res.data.per_page, // items per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('SET_UPCOMING_PAGINATED', pagination);
                commit('IS_LOADING', false);
                resolve(200);
            }).catch((error) => {
                commit('IS_LOADING', false);
                reject(error.response);
            })
        });
    },

    fetchDocuments({ commit }, id) {
        commit('IS_FILE_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`add-tender-info/tender-information/documents/${id}`)
                .then(res => {
                    commit('DOCUMENTS', res.data.payload.data);
                    commit('IS_FILE_LOADING', false);
                    resolve(200);
                }).catch((error) => {
                    commit('IS_FILE_LOADING', false);

                    reject(error.response);
                });
        });
    },
    updateApproved({ commit }, items) {
        commit('IS_APPROVED', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post(`add-tender-info/tender-information/is_approved/${items.id}`, items)
                .then(() => {
                    commit('IS_APPROVED', false);
                    resolve(200);
                }).catch((error) => {
                    commit('IS_APPROVED', false);

                    reject(error.response);
                });
        });
    },


};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}