import instanceAxios from '@/utility/axios.js'

// initial state
const state = () => ({
    total: null,
    isLoading: false,

    isGraphLoading: false,
    graph: null,

    isSuccessVsFailureLoading: false,
    successVsFailure: null,

    isNotificationsLoading: false,
    notifications: null,

})

// getters
const getters = {
    total: state => state.total,
    isLoading: state => state.isLoading,

    isGraphLoading: state => state.isGraphLoading,
    graph: state => state.graph,
    
    isSuccessVsFailureLoading: state => state.isSuccessVsFailureLoading,
    successVsFailure: state => state.successVsFailure,

    isNotificationsLoading: state => state.isNotificationsLoading,
    notifications: state => state.notifications,


};

// mutations
const mutations = {
    TOTAL: (state, total) => {
        state.total = total
    },
    IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    GRAPH: (state, graph) => {
        state.graph = graph
    },
    IS_GRAPH_LOADING(state, isGraphLoading) {
        state.isGraphLoading = isGraphLoading
    },

    SUCCESS_VS_FAILURE: (state, successVsFailure) => {
        state.successVsFailure = successVsFailure
    },
    IS_SUCCESS_VS_FAILURE_LOADING(state, isSuccessVsFailureLoading) {
        state.isSuccessVsFailureLoading = isSuccessVsFailureLoading
    },

    NOTIFICATIONS: (state, notifications) => {
        state.notifications = notifications
    },
    IS_NOTIFICATION_LOADING(state, isNotificationsLoading) {
        state.isNotificationsLoading = isNotificationsLoading
    },

}

// actions
const actions = {
    fetchTotal({ commit }, query = null) {
        commit('IS_LOADING', true);
        let concern_id = 0;
        if (query !== null) {
            concern_id = query.concern_id;
        }
        let url = `dashboard/dashboard-total?concern_id=${concern_id}`;
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url)
                .then(res => {
                    commit('TOTAL', res.data.payload.data);
                    commit('IS_LOADING', false);
                    resolve(200);
                }).catch(err => {
                    commit('IS_LOADING', false);
                    console.log('error', err);
                    reject(422);
                });
        });
    },
    fetchGraph({ commit }, data) {
        commit('IS_GRAPH_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post(`dashboard/tender-graph`, data)
                .then(res => {
                    commit('GRAPH', res.data.payload.data);
                    commit('IS_GRAPH_LOADING', false);
                    resolve(200);
                }).catch(err => {
                    commit('IS_GRAPH_LOADING', false);
                    console.log('error', err);
                    reject(422);
                });
        });
    },
    fetchSuccessVsFailure({ commit }, data) {
        commit('IS_SUCCESS_VS_FAILURE_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post(`dashboard/success-vs-failure`, data)
                .then(res => {
                    commit('SUCCESS_VS_FAILURE', res.data.payload);
                    commit('IS_SUCCESS_VS_FAILURE_LOADING', false);
                    resolve(200);
                }).catch(err => {
                    commit('IS_SUCCESS_VS_FAILURE_LOADING', false);
                    console.log('error', err);
                    reject(422);
                });
        });
    },
    fetchNotifications({ commit }, query = null) {
        commit('IS_NOTIFICATION_LOADING', true);
        let concern_id = 0;
        if (query !== null) {
            concern_id = query.concern_id;
        }
        let url= `dashboard/notification?concern_id=${concern_id}`;
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url)
                .then(res => {
                    commit('NOTIFICATIONS', res.data.payload.data);
                    commit('IS_NOTIFICATION_LOADING', false);
                    resolve(200);
                }).catch(err => {
                    commit('IS_NOTIFICATION_LOADING', false);
                    console.log('error', err);
                    reject(422);
                });
        });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}