import axiosDownload from '@/utility/axiosDownload.js'
import instanceAxios from '@/utility/axios.js'
import { saveByteArray, base64ToArrayBuffer } from '@/utility/common.js'

// initial state
const state = () => ({
    isLoading: false,
    isWoLoading: false,
    isWoDLoading: false,
    contactAward: null,
    contactAwardPagination: null,
    failure: null,
    failurePagination: null,
    securityMoney: null,
    securityMoneyPagination: null,
    tenders: null,
    tenderDetails: null,
    failureDetails: null,
    work_order_summary_report: null,
    work_order_details_report: null,
})

// getters
const getters = {
    isLoading: state => state.isLoading,
    isWoLoading: state => state.isWoLoading,
    isWoDLoading: state => state.isWoDLoading,
    contactAward: state => state.contactAward,
    contactAwardPagination: state => state.contactAwardPagination,
    failure: state => state.failure,
    failurePagination: state => state.failurePagination,
    securityMoney: state => state.securityMoney,
    securityMoneyPagination: state => state.securityMoneyPagination,
    tenders: state => state.tenders,
    tenderDetails: state => state.tenderDetails,
    failureDetails: state => state.failureDetails,
    getWorkOrderSummaryReport: state => state.work_order_summary_report,
    getWorkOrderDetailsReport: state => state.work_order_details_report,
};

// mutations
const mutations = {
    IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    IS_WO_LOADING(state, isWoLoading) {
        state.isWoLoading = isWoLoading
    },
    IS_WOD_LOADING(state, isWoDLoading) {
        state.isWoDLoading = isWoDLoading
    },
    CONTACT_AWARD(state, contactAward) {
        state.contactAward = contactAward
    },
    CONTACT_AWARD_PAGINATION(state, contactAwardPagination) {
        state.contactAwardPagination = contactAwardPagination
    },
    FAILURE(state, failure) {
        state.failure = failure
    },
    FAILURE_PAGINATION(state, failurePagination) {
        state.failurePagination = failurePagination
    },
    SECURITY_MONEY(state, securityMoney) {
        state.securityMoney = securityMoney
    },
    SECURITY_MONEY_PAGINATION(state, securityMoneyPagination) {
        state.securityMoneyPagination = securityMoneyPagination
    },
    TENDERS(state, tenders) {
        state.tenders = tenders
    },
    TENDER_DETAILS(state, tenderDetails) {
        state.tenderDetails = tenderDetails
    },
    FAILURE_DETAILS(state, failureDetails) {
        state.failureDetails = failureDetails
    },
    WORK_ORDER_SUMMARY_REPORT(state, work_order_summary_report) {
        state.work_order_summary_report = work_order_summary_report
    },
    WORK_ORDER_DETAILS_REPORT(state, work_order_details_report) {
        state.work_order_details_report = work_order_details_report
    }
}

// actions
const actions = {
    fetchContactAward: ({ commit }, query = null) => {
        commit('IS_LOADING', true);
        let page = '';
        let search = '';
        let pageLength = 10;
        let date_between_name = '';
        let date_start = '';
        let date_end = '';
        let concern_id = 0;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
            date_between_name = query.date_between_name;
            concern_id = query.concern_id;
            date_start = query.date_start;
            date_end = query.date_end;
        }
        let url = `contact-award?pageLength=${pageLength}&concern_id=${concern_id}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }

        if (date_between_name !== null && date_between_name !== "" && date_start !== null && date_start !== "" && date_end !== null && date_end !== "") {
            url = `${url}&date_between_name=${date_between_name}&date_start=${date_start}&date_end=${date_end}`
        }

        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                const items = res.data.data;
                commit('CONTACT_AWARD', items);
                const pagination = {
                    total: res.data.total,  // total number of elements or items
                    per_page: res.data.per_page, // items per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('CONTACT_AWARD_PAGINATION', pagination);
                commit('IS_LOADING', false);
                resolve(200);
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(422);
            })
        });
    },
    fetchFailure: ({ commit }, query = null) => {
        commit('IS_LOADING', true);
        let page = '';
        let search = '';
        let pageLength = 10;
        let date_between_name = '';
        let date_start = '';
        let date_end = '';
        let concern_id = 0;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
            date_between_name = query.date_between_name;
            date_start = query.date_start;
            date_end = query.date_end;
            concern_id = query.concern_id;
        }
        let url = `failure?pageLength=${pageLength}&concern_id=${concern_id}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }

        if (date_between_name !== null && date_between_name !== "" && date_start !== null && date_start !== "" && date_end !== null && date_end !== "") {
            url = `${url}&date_between_name=${date_between_name}&date_start=${date_start}&date_end=${date_end}`
        }

        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                const items = res.data.data;
                commit('FAILURE', items);
                const pagination = {
                    total: res.data.total,  // total number of elements or items
                    per_page: res.data.per_page, // items per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('FAILURE_PAGINATION', pagination);
                commit('IS_LOADING', false);
                resolve(200);
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(422);
            })
        });
    },
    fetchSecurityMoney: ({ commit }, query = null) => {
        commit('IS_LOADING', true);
        let page = '';
        let search = '';
        let pageLength = 10;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
        }
        let url = `security-money?pageLength=${pageLength}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                const items = res.data.data;
                commit('SECURITY_MONEY', items);
                const pagination = {
                    total: res.data.total,  // total number of elements or items
                    per_page: res.data.per_page, // items per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('SECURITY_MONEY_PAGINATION', pagination);
                commit('IS_LOADING', false);
                resolve(200);
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(422);
            })
        });
    },
    fetchTenderInfos: ({ commit }) => {
        commit('IS_LOADING', true);
        let url = `get-tenders`;
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                commit('TENDERS', res.data.payload.data);
                commit('IS_LOADING', false);
                resolve(200);
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(422);
            })
        });
    },
    fetchTenderDetails: ({ commit }, id) => {
        commit('IS_LOADING', true);
        let url = `id-by-tender-details/${id}`;
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                commit('TENDER_DETAILS', res.data.payload);
                commit('IS_LOADING', false);
                resolve(200);
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(422);
            })
        });
    },
    fetchWorkOrderSummaryReport: ({ commit }, items) => {
        commit('IS_WO_LOADING', true);
        let url = `get-wo-summary-report/${items.selectedyear}?concern_id=${items.concern_id}`;
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                commit('WORK_ORDER_SUMMARY_REPORT', res.data.payload.data);
                commit('IS_WO_LOADING', false);
                resolve(200);
            }).catch(() => {
                commit('IS_WO_LOADING', false);
                reject(422);
            })
        });
    },
    fetchWorkOrderDetailsReport: ({ commit }, items) => {
        commit('IS_WOD_LOADING', true);
        let url = `get-wo-details-report/${items.selectedyear}?concern_id=${items.concern_id}`; 

        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                commit('WORK_ORDER_DETAILS_REPORT', res.data.payload);
                commit('IS_WOD_LOADING', false);
                resolve(200);
            }).catch(() => {
                commit('IS_WOD_LOADING', false);
                reject(422);
            })
        });
    },
    download_tender_details: ({ commit }, details) => {
        commit('IS_LOADING', true);
        let url = `tender-infos/download/${details.id}?type=${details.type}`;
        return new Promise(function (resolve, reject) {
            axiosDownload.get(url).then(response => {
                // download binary array
                var sampleArr = base64ToArrayBuffer(response.data);
                saveByteArray(sampleArr, "application/pdf");
                commit('IS_LOADING', false);
                resolve(200);
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(404);
            });
        });
    },
    fetchFailureItems: ({ commit }, id) => {
        let url = `failure/item/${id}`;
        return new Promise(function (resolve, reject) {
            axiosDownload.get(url).then(response => {
                commit('FAILURE_DETAILS', response.data.payload);
                resolve(200);
            }).catch(() => {
                reject(404);
            });
        });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}