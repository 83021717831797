<template>
    <vue-progress-bar></vue-progress-bar>
    <component :is="this.$route.meta.layout || 'PublicLayout'">
        <router-view />
    </component>
</template>

<script>
import AdminLayout from "@/views/layout/AdminLayout.vue";
import PublicLayout from "@/views/layout/PublicLayout.vue";
import { mapActions, mapGetters } from "vuex";
export default {
    components: {
        AdminLayout,
        PublicLayout,
    },
    data() {
        return {
            permision: null,
        }
    },
    computed: {
        ...mapGetters({
            list: "permissions/listWithoutPagination",
            isAuthenticated: "auth/isAuthenticated"
        }),
    },
    methods: {
        ...mapActions({
            fetchPermissionsList: "permissions/fetchWithoutPagiantionList"
        }),
    },
    created() {
        this.$Progress.start();
        //  hook the progress bar to start before we move router-view

        this.$router.beforeEach((to, from, next) => {

            //  does the page we want to go to have a meta.progress object
            if (to.meta.progress !== undefined) {
                let meta = to.meta.progress;
                // parse meta tags
                this.$Progress.parseMeta(meta);
            }
            //  start the progress bar
            this.$Progress.start();
            //  continue to next page
            next();
        });

        //  hook the progress bar to finish after we've finished moving router-view
        this.$router.afterEach(() => {
            //  finish the progress bar
            this.$Progress.finish();
        });
    },
};
</script>

<style lang="scss">
#app {
    font-family: $ff_Roboto;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $main_color;
    border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $main_color;
    border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #b30000;
}


.form-control-modify {
    background-color: rgba(224, 231, 255, 0.19);
    border: 0.5px solid #e0e7ff;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 10px;
    color: rgba(45, 55, 72, 0.5);
    font-size: 15px;
}

.form-control-modify:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem transparent;
}
</style>
