import instanceAxios from '@/utility/axios.js'

// initial state
const state = () => ({
    isLoading: false,
    list: null,
    listWithoutPagination: null
})

// getters
const getters = {
    isLoading: state => state.isLoading,
    list: state => state.list,
    listWithoutPagination: state => state.listWithoutPagination,
};

// mutations
const mutations = {
    IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    LIST: (state, list) => {
        state.list = list
    },
    LIST_WITHOUT_PAGINATION: (state, listWithoutPagination) => {
        state.listWithoutPagination = listWithoutPagination
    }
}

// actions
const actions = {

    fetchWithoutPagiantionList({ commit }) {
        commit('IS_LOADING', true);
        let url = `permissions`;
        return new Promise((resolve, reject) => {
            instanceAxios.get(url)
                .then(res => {
                    resolve(200);
                    commit('LIST_WITHOUT_PAGINATION', res.data.payload.data);
                    commit('IS_LOADING', false);
                }).catch(() => {
                    reject(404);
                    commit('IS_LOADING', false);
                });

        });

    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}