import instanceAxios from '@/utility/axios.js'

const state = {
    items: [],
    paginatedData: null,

    draftItems: [],
    paginatedDraftData: null,

    details: [],
    tenderInfosById: [],
    documents: [],

    common: [],
    validation_errors: [],
    errors: [],

    isLoading: false,
    isDraftLoading: false,
    isLoadingDoc: false,

    isCreating: false,
    isUpdating: false,
    isDeleting: false,

    isFileDeleting: false,

    is_uplaod_start: false,
    uplaoded_progress: 0,

};
const getters = {
    list: state => state.items,
    paginatedData: state => state.paginatedData,

    draftList: state => state.draftItems,
    paginatedDraftData: state => state.paginatedDraftData,

    details: state => state.details,
    tenderInfosById: state => state.tenderInfosById,
    documents: state => state.documents,

    common: state => state.common,

    validation_errors: state => state.validation_errors,
    errors: state => state.errors,

    isLoading: state => state.isLoading,
    isLoadingDoc: state => state.isLoadingDoc,
    isDraftLoading: state => state.isDraftLoading,
    isCreating: state => state.isCreating,
    isUpdating: state => state.isUpdating,
    isDeleting: state => state.isDeleting,
    isFileDeleting: state => state.isFileDeleting,

    is_uplaod_start: state => state.is_uplaod_start,
    uplaoded_progress: state => state.uplaoded_progress

};
const mutations = {
    SET_ITEMS: (state, items) => {
        state.items = items
    },
    SET_PAGINATED: (state, paginatedData) => {
        state.paginatedData = paginatedData
    },
    SET_DRAFT_ITEMS: (state, draftItems) => {
        state.draftItems = draftItems
    },
    SET_DRAFT_PAGINATED: (state, paginatedDraftData) => {
        state.paginatedDraftData = paginatedDraftData
    },
    SET_DETAIL: (state, details) => {
        state.details = details
    },
    SET_TENDER_INFOS_BY_ID: (state, tenderInfosById) => {
        state.tenderInfosById = tenderInfosById
    },
    SET_TENDER_SUBMISSION_DOCS_BY_ID: (state, documents) => {
        state.documents = documents
    },
    COMMON: (state, item) => {
        return state.common = item;
    },
    SET_VALIDATION_ERRORS: (state, validation_errors) => {
        state.validation_errors = validation_errors;
    },
    SET_ERRORS: (state, errors) => {
        state.errors = errors;
    },
    IS_LOADING(state, isLoading) {
        state.isLoading = isLoading;
    },
    IS_LOADING_DOC(state, isLoadingDoc) {
        state.isLoadingDoc = isLoadingDoc;
    },
    IS_DRAFT_LOADING(state, isDraftLoading) {
        state.isDraftLoading = isDraftLoading;
    },
    SET_CREATING(state, isCreating) {
        state.isCreating = isCreating;
    },
    SET_UPDATING(state, isUpdating) {
        state.isUpdating = isUpdating;
    },
    SET_DELETING(state, isDeleting) {
        state.isDeleting = isDeleting;
    },
    SET_FILE_DELETING(state, isFileDeleting) {
        state.isFileDeleting = isFileDeleting;
    },
    SET_UPLOADED_STATUS: (state, is_uplaod_start) => {
        state.is_uplaod_start = is_uplaod_start;
    },
    SET_UPLOADED_PROGRESS: (state, uplaoded_progress) => {
        state.uplaoded_progress = uplaoded_progress;
    }
};
const actions = {
    fetchList: ({ commit }, query = null) => {
        commit('IS_LOADING', true);
        let page = '';
        let search = '';
        let pageLength = 10;
        let date_between_name = '';
        let date_start = '';
        let date_end = '';
        let concern_id = 0;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
            date_between_name = query.date_between_name;
            date_start = query.date_start;
            date_end = query.date_end;
            concern_id = query.concern_id;
        }
        let url = `bills/bill-submissions?is_draft=0&pageLength=${pageLength}&concern_id=${concern_id}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }

        if (date_between_name !== null && date_between_name !== "" && date_start !== null && date_start !== "" && date_end !== null && date_end !== "") {
            url = `${url}&date_between_name=${date_between_name}&date_start=${date_start}&date_end=${date_end}`
        }

        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                const items = res.data.data;
                commit('SET_ITEMS', items);
                const pagination = {
                    total: res.data.total,  // total number of elements or items
                    per_page: res.data.per_page, // items per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('SET_PAGINATED', pagination);
                commit('IS_LOADING', false);
                resolve(200);
            }).catch((error) => {
                commit('IS_LOADING', false);
                reject(error.response);
            })
        });
    },
    fetchDraftItems: ({ commit }, query = null) => {
        commit('IS_DRAFT_LOADING', true);
        let page = '';
        let search = '';
        let pageLength = 10;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
        }
        let url = `bills/bill-submissions?is_draft=1&pageLength=${pageLength}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                const items = res.data.data;
                commit('SET_DRAFT_ITEMS', items);
                const pagination = {
                    total: res.data.total,  // total number of elements or items
                    per_page: res.data.per_page, // items per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('SET_DRAFT_PAGINATED', pagination);
                commit('IS_DRAFT_LOADING', false);
                resolve(200);
            }).catch((error) => {
                commit('IS_DRAFT_LOADING', false);
                reject(error.response);
            })
        });
    },
    fetchDetail({ commit }, id) {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`bills/bill-submissions/${id}`)
                .then(res => {
                    commit('SET_DETAIL', res.data.payload.data);
                    commit('IS_LOADING', false);
                    resolve(200);
                }).catch((error) => {
                    commit('IS_LOADING', false);
                    reject(error.response);
                });
        });
    },
    add: ({ commit }, items) => {
        commit('SET_CREATING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post('bills/bill-submissions', items).then(response => {
                if (response.status == 200) {
                    commit('SET_CREATING', false);
                    commit('SET_ERRORS', []);
                    resolve(200);
                }
            }).catch((error) => {
                commit('SET_CREATING', false);
                if (error.response.status == 422) {
                    commit('SET_ERRORS', error.response.data);
                }
                reject(error.response);
            })
        });
    },
    update: ({ commit }, items) => {
        commit('SET_UPDATING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post(`bills/bill-submissions/${items.get('id')}`, items).then(response => {
                if (response.status == 200) {
                    commit('SET_UPDATING', false);
                    commit('SET_ERRORS', []);
                    resolve(200);
                }
            }).catch((error) => {
                commit('SET_UPDATING', false);
                if (error.response.status == 422) {
                    commit('SET_ERRORS', error.response.data);
                }
                reject(error.response);
            })
        });
    },

    deleteItem({ commit }, id) {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.delete(`bills/bill-submissions/${id}`)
                .then(() => {
                    commit('IS_LOADING', false);
                    resolve(200);
                }).catch((error) => {
                    commit('IS_LOADING', false);
                    reject(error.response);
                });
        });
    },

    // update file delete
    deleteUploadedFile: ({ commit }, items) => {
        commit("SET_FILE_DELETING", true);
        return new Promise(function (resolve, reject) {
            instanceAxios.delete(`add-tender-info/tender_submission/delete-document/${items.id}`).then(response => {
                if (response.status == 200) {
                    commit("SET_FILE_DELETING", false);
                    resolve(200);
                }
            }).catch((error) => {
                commit("SET_FILE_DELETING", false);
                reject(error.response);
            })
        });
    },
    // update file 
    uploadUpdateFile: ({ commit }, items) => {
        commit("SET_UPLOADED_STATUS", true);
        return new Promise(function (resolve, reject) {
            const config = {
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    commit("SET_UPLOADED_PROGRESS", percentCompleted);
                }
            };
            instanceAxios.post(`add-tender-info/tender_submission/add-document`, items, config).then(response => {
                if (response.status == 200) {
                    commit("SET_UPLOADED_STATUS", false);
                    commit("SET_UPLOADED_PROGRESS", 0);
                    resolve(200);
                }
            }).catch((error) => {
                commit("SET_UPLOADED_STATUS", false);
                commit("SET_UPLOADED_PROGRESS", 0);
                reject(error.response);
            })
        });

    },
    fetchCommon: ({ commit }) => {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get('bills/bill-submission/common').then(response => {
                if (response.status == 200) {
                    // set auth user state
                    commit('COMMON', response.data.payload);
                    commit('IS_LOADING', false);
                    resolve(200);
                }
            }).catch((error) => {
                commit('IS_LOADING', false);
                reject(error.response);
            })
        });
    },
    fetchTenderInfoByID: ({ commit }, id) => {
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`bills/bill-submission/${id}`).then(response => {
                if (response.status == 200) {
                    // set auth user state
                    commit('SET_TENDER_INFOS_BY_ID', response.data.payload);
                    resolve(200);
                }
            }).catch((error) => {
                commit('SET_TENDER_INFOS_BY_ID', '');
                reject(error.response);
            })
        });
    }

};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}