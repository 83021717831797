import instanceAxios from '@/utility/axios.js'

// initial state
const state = () => ({
    list: null,
    paginatedData: null,
    details: null,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    common: null,
    isSpining: false,

    errorMessage: '',
    errors: {},

    list_without_pagination: null,
    profileDetails: {
        name: "User",
        email: "admin@admin.com",
        profile_path: `${require("@/assets/img/avatar/dummy.png")}`
    },
    profileDetailsLoading: false,
    userConcerns: null,
    roles: [],
})

// getters
const getters = {
    list: state => state.list,
    paginatedData: state => state.paginatedData,
    details: state => state.details,
    isLoading: state => state.isLoading,
    isSpining: state => state.isSpining,
    isCreating: state => state.isCreating,
    isUpdating: state => state.isUpdating,
    errorMessage: state => state.errorMessage,
    errors: state => state.errors,
    list_without_pagination: state => state.list_without_pagination,
    profileDetails: state => state.profileDetails,
    profileDetailsLoading: state => state.profileDetailsLoading,
    common: state => state.common,
    userConcerns: state => state.userConcerns,
    roles: state => state.roles,
};

// mutations
const mutations = {
    SET_ITEMS: (state, list) => {
        state.list = list
    },
    SET_PAGINATED: (state, paginatedData) => {
        state.paginatedData = paginatedData
    },
    SET_DETAIL: (state, details) => {
        state.details = details
    },
    IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    IS_SPINING(state, isSpining) {
        state.isSpining = isSpining
    },
    SET_CREATING(state, isCreated) {
        state.isCreating = isCreated;
    },
    SET_UPDATING(state, isUpdated) {
        state.isUpdating = isUpdated;
    },
    SET_ERROR_MSG(state, errorMsg) {
        state.errorMessage = errorMsg;
    },
    SET_ERRORS(state, error) {
        state.errors = error;
    },
    SET_NULL_ERRORS(state, error) {
        state.errors = error;
    },
    SET_LIST_WITHOUT_PAGINATION(state, list_without_pagination) {
        state.list_without_pagination = list_without_pagination;
    },
    SET_USER_PROFILE_DETAILS(state, data) {
        state.profileDetails = data;
    },
    SET_USER_PROFILE_LOADING(state, data) {
        state.profileDetailsLoading = data;
    },
    SET_COMMON(state, common) {
        state.common = common;
    },
    SET_USER_CONCERNS(state, userConcerns) {
        state.userConcerns = userConcerns;
    },
    ROLES(state, roles) {
        state.roles = roles;
    }
}

// actions
const actions = {
    fetchList({ commit }, query = null) {
        let page = '';
        let search = '';
        let pageLength = 10;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
        }
        commit('IS_LOADING', true);
        let url = `users?pageLength=${pageLength}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }
        instanceAxios.get(url)
            .then(res => {

                const list = res.data.data;
                commit('SET_ITEMS', list);
                const pagination = {
                    total: res.data.total,  // total number of elements or list
                    per_page: res.data.per_page, // list per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('SET_PAGINATED', pagination);
                commit('IS_LOADING', false);

            }).catch(err => {
                console.log('error', err);
                commit('IS_LOADING', false);
            });
    },
    fetchDetail({ commit }, id) {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`user/${id}`)
                .then(res => {
                    commit('SET_DETAIL', res.data.payload.data);
                    commit('ROLES', res.data.payload.roles);
                    commit('IS_LOADING', false);
                    resolve(200);
                }).catch(err => {
                    commit('IS_LOADING', false);
                    console.log('error', err);
                    reject(422);
                });
        });
    },
    storeItem({ commit }, details) {
        commit('SET_CREATING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post(`users`, details)
                .then(() => {
                    commit('SET_CREATING', false);
                    resolve(200);
                }).catch(err => {
                    console.log('error', err);
                    // Only validation errors
                    if (err.response.status === 422) {
                        commit('SET_ERRORS', err.response.data);
                    }
                    reject(422);
                    commit('SET_CREATING', false);
                });
        });

    },
    updateItem({ commit }, details) {
        commit('SET_UPDATING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post(`users/${details.id}`, details)
                .then(() => {
                    commit('SET_UPDATING', false);
                    resolve(200);
                }).catch(err => {
                    console.log('error', err);
                    commit('SET_UPDATING', false);
                    // Only validation errors
                    if (err.response.status === 422) {
                        commit('SET_ERRORS', err.response.data);
                    }
                    reject(422);
                });
        });

    },
    deleteItem({ commit }, id) {
        commit('IS_LOADING', true);
        instanceAxios.delete(`users/${id}`)
            .then(() => {
                commit('IS_LOADING', false);
            }).catch(() => {
                commit('IS_LOADING', false);
            });
    },
    updateStatus(_, data) {
        instanceAxios.post(`users/updateStatus/${data.get('id')}`, data)
            .then(() => {
            }).catch(err => {
                console.log('error', err);
            });
    },
    setErrorEmpty({ commit }) {
        commit('SET_NULL_ERRORS', {});
    },
    fetchListWithoutPagination({ commit }) {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`users-for-assign`)
                .then(res => {
                    commit('SET_LIST_WITHOUT_PAGINATION', res.data.payload.data);
                    commit('IS_LOADING', false);
                    resolve(200);
                }).catch(err => {
                    commit('IS_LOADING', false);
                    console.log('error', err);
                    reject(422);
                });
        });
    },
    profileDetails({ commit }) {
        commit('SET_USER_PROFILE_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`user`)
                .then(res => {
                    commit('SET_USER_PROFILE_DETAILS', res.data.payload.data);
                    commit('SET_USER_PROFILE_LOADING', false);
                    resolve(200);
                }).catch(err => {
                    commit('SET_USER_PROFILE_LOADING', false);
                    console.log('error', err);
                    reject(422);
                });
        });
    },
    updatePassword({ commit }, formData) {
        commit('SET_USER_PROFILE_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post(`user/change-password`, formData)
                .then(res => {
                    commit('SET_USER_PROFILE_LOADING', false);
                    if (res.payload.status)
                        resolve(200);
                    else
                        reject(422);
                }).catch(err => {
                    commit('SET_USER_PROFILE_LOADING', false);
                    console.log('error', err);
                    reject(422);
                });
        });
    },
    updateProfile({ commit }, formData) {
        commit('SET_USER_PROFILE_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post(`user/update-profile`, formData)
                .then(res => {
                    commit('SET_USER_PROFILE_LOADING', false);
                    commit('SET_USER_PROFILE_DETAILS', res.data.payload.data);
                    resolve(200);
                }).catch(err => {
                    commit('SET_USER_PROFILE_LOADING', false);
                    console.log('error', err);
                    reject(422);
                });
        });
    },
    commonAction({ commit }) {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`user/common`)
                .then(res => {
                    commit('IS_LOADING', false);
                    commit('SET_COMMON', res.data.payload);
                    resolve(200);
                }).catch(() => {
                    commit('IS_LOADING', false);
                    reject(422);
                });
        });
    },
    userConcernsAction({ commit }, id) {
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`user/concerns/${id}`)
                .then(res => {
                    commit('SET_USER_CONCERNS', res.data.payload);
                    resolve(200);
                }).catch(() => {
                    reject(422);
                });
        });
    },
    assaignConcernAction({ commit }, formData) {
        commit('IS_SPINING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post(`user/assaign-concern`, formData)
                .then(() => {
                    commit('IS_SPINING', false);
                    resolve(200);
                }).catch(err => {
                    commit('IS_SPINING', false);
                    console.log('error', err);
                    reject(422);
                });
        });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}