import instanceAxios from '@/utility/axios.js'

const state = {
    // common 
    isLoading: false,
    // create
    isCreating: false,
    isDraftLoading: false,
    draftList: null,
    paginatedDraftData: null,
    common: null,
    isChangeIdLoading: false,
    changeIdDetails: null,
    // show data
    list: null,
    paginatedData: null,
    // update
    isUploading: false,
    isDetailLoading: false,
    details: null,
    isChangeIdUpdateLoading: false,
    changeIdUpdateDetails: null,
    isDocLoading: false,
    documents: null,
    isUplaodStart: false,
    uplaodProgress: 0,
    isDeletingFile: false,
    //delete
    isDeleting: false,
    //validation errors
    validation_errors: null,
    errors: null,

};
const getters = {
    // common 
    isLoading: state => state.isLoading,
    // create
    isCreating: state => state.isCreating,
    isDraftLoading: state => state.isDraftLoading,
    draftList: state => state.draftList,
    paginatedDraftData: state => state.paginatedDraftData,
    common: state => state.common,
    isChangeIdLoading: state => state.isChangeIdLoading,
    changeIdDetails: state => state.changeIdDetails,
    // show data
    list: state => state.list,
    paginatedData: state => state.paginatedData,
    // update
    isUploading: state => state.isUploading,
    isDetailLoading: state => state.isDetailLoading,
    details: state => state.details,
    isChangeIdUpdateLoading: state => state.isChangeIdUpdateLoading,
    changeIdUpdateDetails: state => state.changeIdUpdateDetails,
    isDocLoading: state => state.isDocLoading,
    documents: state => state.documents,
    isUplaodStart: state => state.isUplaodStart,
    uplaodProgress: state => state.uplaodProgress,
    isDeletingFile: state => state.isDeletingFile,
    //deleting
    isDeleting: state => state.isDeleting,

};
const mutations = {
    // common 
    IS_LOADING(state, isLoading) {
        state.isLoading = isLoading;
    },
    // create
    IS_CREATING(state, isCreating) {
        state.isCreating = isCreating;
    },
    IS_DRAFT_LOADING(state, isDraftLoading) {
        state.isDraftLoading = isDraftLoading;
    },
    DRAFT_LIST: (state, draftList) => {
        state.draftList = draftList
    },
    DRAFT_PAGINATED: (state, paginatedDraftData) => {
        state.paginatedDraftData = paginatedDraftData
    },
    COMMON: (state, common) => {
        state.common = common;
    },
    IS_CHANGED_ID_LOADING: (state, isChangeIdLoading) => {
        state.isChangeIdLoading = isChangeIdLoading;
    },
    CHANGE_ID_DETAILS: (state, changeIdDetails) => {
        state.changeIdDetails = changeIdDetails;
    },
    // show data
    LIST: (state, list) => {
        state.list = list
    },
    PAGINATED_DATA: (state, paginatedData) => {
        state.paginatedData = paginatedData
    },
    // update
    IS_UPLOADING: (state, isUploading) => {
        state.isUploading = isUploading
    },
    IS_DETAIL_LOADING: (state, isDetailLoading) => {
        state.isDetailLoading = isDetailLoading
    },
    DETAILS: (state, details) => {
        state.details = details
    },
    IS_CHANGE_ID_UPDATED_LOADING: (state, isChangeIdUpdateLoading) => {
        state.isChangeIdUpdateLoading = isChangeIdUpdateLoading
    },
    CHANGE_ID_UPDATED_DETAILS: (state, changeIdUpdateDetails) => {
        state.changeIdUpdateDetails = changeIdUpdateDetails
    },
    IS_DOC_LOADING: (state, isDocLoading) => {
        state.isDocLoading = isDocLoading
    },
    DOCUMENTS: (state, documents) => {
        state.documents = documents
    },
    IS_UPLOAD_START: (state, isUplaodStart) => {
        state.isUplaodStart = isUplaodStart
    },
    UPLOAD_PROGRESS: (state, uplaodProgress) => {
        state.uplaodProgress = uplaodProgress
    },
    IS_DELETING_FILE: (state, isDeletingFile) => {
        state.isDeletingFile = isDeletingFile
    },
    //delete
    IS_DELETING: (state, isDeleting) => {
        state.isDeleting = isDeleting
    },

};
const actions = {
    ///Fetching all data
    // create page
    fetchDraftList: ({ commit }, query = null) => {
        commit('IS_DRAFT_LOADING', true);
        let page = '';
        let search = '';
        let pageLength = 10;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
        }
        let url = `add-tender-info/award-and-executions?is_draft=1&pageLength=${pageLength}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                const items = res.data.data;
                commit('DRAFT_LIST', items);
                const pagination = {
                    total: res.data.total,  // total number of elements or items
                    per_page: res.data.per_page, // items per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('DRAFT_PAGINATED', pagination);
                commit('IS_DRAFT_LOADING', false);
                resolve(200);
            }).catch(() => {
                commit('IS_DRAFT_LOADING', false);
                reject(422);
            })
        });
    },
    fetchCommon: ({ commit }) => {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get('add-tender-info/award-and-execution/common').then(response => {
                if (response.status == 200) {
                    // set auth user state
                    commit('COMMON', response.data.payload);
                    commit('IS_LOADING', false);
                    resolve(200);
                }
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(422);
            })
        });
    },
    fetchChangeIdDetails: ({ commit }, id) => {
        commit('IS_CHANGED_ID_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`add-tender-info/award-and-execution/tender-info/${id}`).then(response => {
                if (response.status == 200) {
                    // set auth user state
                    commit('CHANGE_ID_DETAILS', response.data.payload.data);
                    commit('IS_CHANGED_ID_LOADING', false);
                    resolve(200);
                }
            }).catch(() => {
                commit('IS_CHANGED_ID_LOADING', false);
                reject(422);
            })
        });
    },
    // show page
    fetchItems: ({ commit }, query = null) => {
        commit('IS_LOADING', true);
        let page = '';
        let search = '';
        let pageLength = 10;
        let concern_id = 0;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
            concern_id = query.concern_id;
        }
        let url = `add-tender-info/award-and-executions?is_draft=0&pageLength=${pageLength}&concern_id=${concern_id}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url).then(res => {
                const items = res.data.data;
                commit('LIST', items);
                const pagination = {
                    total: res.data.total,  // total number of elements or items
                    per_page: res.data.per_page, // items per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('PAGINATED_DATA', pagination);
                commit('IS_LOADING', false);
                resolve(200);
            }).catch(() => {
                commit('IS_LOADING', false);
                reject(422);
            })
        });
    },
    // edit page
    fetchDetail({ commit }, id) {
        commit('IS_DETAIL_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`add-tender-info/award-and-executions/${id}`)
                .then(res => {
                    commit('DETAILS', res.data.payload.data);
                    commit('IS_DETAIL_LOADING', false);
                    resolve(200);
                }).catch(err => {
                    commit('IS_DETAIL_LOADING', false);
                    console.log('error', err);
                    reject(422);
                });
        });
    },
    fetchDetailChangeId({ commit }, id) {
        commit('IS_CHANGE_ID_UPDATED_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`add-tender-info/award-and-executions/${id}`)
                .then(res => {
                    commit('CHANGE_ID_UPDATED_DETAILS', res.data.payload.data);
                    commit('IS_CHANGE_ID_UPDATED_LOADING', false);
                    resolve(200);
                }).catch(err => {
                    commit('IS_CHANGE_ID_UPDATED_LOADING', false);
                    console.log('error', err);
                    reject(422);
                });
        });
    },
    fetchDocuments({ commit }, id) {
        commit('IS_DOC_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`add-tender-info/award-and-execution/documents/${id}`)
                .then(res => {
                    commit('DOCUMENTS', res.data.payload.data.documents);
                    commit('IS_DOC_LOADING', false);
                    resolve(200);
                }).catch(err => {
                    commit('IS_DOC_LOADING', false);
                    console.log('error', err);
                    reject(422);
                });
        });
    },
    // Actions 
    // Create page
    add: ({ commit }, items) => {
        commit('IS_CREATING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post('add-tender-info/award-and-executions', items).then(response => {
                if (response.status == 200) {
                    commit('IS_CREATING', false);
                    resolve(200);
                }
            }).catch(() => {
                commit('IS_CREATING', false);
                reject(422);
            })
        });
    },
    // edit page
    update: ({ commit }, items) => {
        commit('IS_UPLOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post(`add-tender-info/award-and-executions/${items.get('id')}`, items).then(response => {
                if (response.status == 200) {
                    commit('IS_UPLOADING', false);
                    resolve(200);
                }
            }).catch(() => {
                commit('IS_UPLOADING', false);
                reject(422);
            })
        });
    },
    //delete
    delete({ commit }, id) {
        commit('IS_DELETING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.delete(`add-tender-info/award-and-executions/${id}`)
                .then(() => {
                    commit('IS_DELETING', false);
                    resolve(200);
                }).catch(() => {
                    commit('IS_DELETING', false);
                    reject(422);
                });
        });
    },
    addFile: ({ commit }, items) => {
        commit("IS_UPLOAD_START", true);
        return new Promise(function (resolve, reject) {
            const config = {
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    commit("UPLOAD_PROGRESS", percentCompleted);
                }
            };
            instanceAxios.post(`add-tender-info/award-and-execution/add-document`, items, config).then(response => {
                if (response.status == 200) {
                    commit("IS_UPLOAD_START", false);
                    commit("UPLOAD_PROGRESS", 0);
                    resolve(200);
                }
            }).catch(() => {
                commit("IS_UPLOAD_START", false);
                commit("UPLOADED_PROGRESS", 0);
                reject(422);
            })
        });
    },
    // update file delete
    deleteFile: ({ commit }, id) => {
        commit("IS_DELETING_FILE", true);
        return new Promise(function (resolve, reject) {
            instanceAxios.delete(`add-tender-info/award-and-execution/delete-document/${id}`).then(response => {
                if (response.status == 200) {
                    commit("IS_DELETING_FILE", false);
                    resolve(200);
                }
            }).catch(() => {
                commit("IS_DELETING_FILE", false);
                reject(422);
            })
        });
    }

};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}