<template>
  <div>
    <div class="card" :style="cardStyle">
      <div class="card-header">
        <h4>{{cardHeader}}</h4>
        <h5 v-if="idNo.length>0">ID: {{idNo}}</h5>
      </div>
      <div
        :style="cardBodyStyle"
        class="card-body"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cardHeader: {
      type: String,
      default: "Card Title",
    },
    idNo: {
      type: String,
      default: "",
    },
    cardBodyStyle: {
      type: Object,
      default: () => ({
        'padding': "1rem 1rem",
      }),
    },
    cardStyle: {
      type: Object,
      default: () => ({
        'border-left': "1px solid rgba(0,0,0,.125)",
        'border-right': "1px solid rgba(0,0,0,.125)",
      }),
    },
  },
};
</script>
<style lang="scss" scoped>
.card-header {
  background: rgba(226, 232, 240, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    color: #2e3192;
    text-transform: capitalize;
  }
  h5 {
    border: 0.5px solid rgba(31, 222, 0, 0.19);
    box-sizing: border-box;
    border-radius: 30px;
    padding: 5px 20px;
    text-transform: capitalize;
    color: #1fde00;
    background-color: $white_color;
  }
}
</style>