import axios from "axios";

// api base url
const instanceAxios = axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

instanceAxios.interceptors.request.use((config) => {
    // config authorization header for all request
    let access_token = localStorage.getItem('access_token');
    config.headers.Authorization = access_token ? `Bearer ${access_token}` : '';
    config.headers.ContentType = 'multipart/form-data';

    return config;
});

instanceAxios.interceptors.response.use((response) => {
    return response;
});

export default instanceAxios; // export axios instanceAxios to be imported in your app