import instanceAxios from '@/utility/axios.js'

// initial state
const state = () => ({
    isPerformanceLoading: false,
    performancelist: null,
    performancePaginatedData: null,

    isRetentionLoading: false,
    retentionlist: null,
    retentionPaginatedData: null,

})

// getters
const getters = {
    isPerformanceLoading: state => state.isPerformanceLoading,
    performancelist: state => state.performancelist,
    performancePaginatedData: state => state.performancePaginatedData,

    isRetentionLoading: state => state.isRetentionLoading,
    retentionlist: state => state.retentionlist,
    retentionPaginatedData: state => state.retentionPaginatedData,

};

// mutations
const mutations = {
    IS_PERFORMANCE_LOADING(state, isPerformanceLoading) {
        state.isPerformanceLoading = isPerformanceLoading
    },
    PERFORMANCE_LIST: (state, performancelist) => {
        state.performancelist = performancelist
    },
    PAGINATED: (state, performancePaginatedData) => {
        state.performancePaginatedData = performancePaginatedData
    },

    IS_RETENTION_LOADING(state, isRetentionLoading) {
        state.isRetentionLoading = isRetentionLoading
    },
    RETENTION_LIST: (state, retentionlist) => {
        state.retentionlist = retentionlist
    },
    RETENTION_PAGINATED: (state, retentionPaginatedData) => {
        state.retentionPaginatedData = retentionPaginatedData
    }



}

// actions
const actions = {
    fetchPerformanceList({ commit }, query = null) {
        let page = '';
        let search = '';
        let pageLength = 10;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
        }
        commit('IS_PERFORMANCE_LOADING', true);
        let url = `performance-security?pageLength=${pageLength}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url)
                .then(res => {
                    const performancelist = res.data.data;
                    commit('PERFORMANCE_LIST', performancelist);
                    const pagination = {
                        total: res.data.total,  // total number of elements or performancelist
                        per_page: res.data.per_page, // performancelist per page
                        current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: res.data.last_page // total pages in record
                    }
                    commit('PAGINATED', pagination);
                    commit('IS_PERFORMANCE_LOADING', false);
                    resolve(200);
                }).catch(err => {
                    console.log('error', err);
                    commit('IS_PERFORMANCE_LOADING', false);
                    reject(422);
                });
        });

    },
    fetchRetentionList({ commit }, query = null) {
        let page = '';
        let search = '';
        let pageLength = 10;
        let concern_id = 0;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
            concern_id = query.concern_id;
        }
        commit('IS_RETENTION_LOADING', true);
        let url = `retention-money?pageLength=${pageLength}&concern_id=${concern_id}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }
        return new Promise(function (resolve, reject) {
            instanceAxios.get(url)
                .then(res => {
                    commit('RETENTION_LIST', res.data.data);
                    const pagination = {
                        total: res.data.total,  // total number of elements or performancelist
                        per_page: res.data.per_page, // performancelist per page
                        current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: res.data.last_page // total pages in record
                    }
                    commit('RETENTION_PAGINATED', pagination);
                    commit('IS_RETENTION_LOADING', false);
                    resolve(200);
                }).catch(err => {
                    console.log('error', err);
                    commit('IS_RETENTION_LOADING', false);
                    reject(404);
                });
        });
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}