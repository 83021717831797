import { createRouter, createWebHistory } from 'vue-router'
import UserLogin from '@/views/user/UserLogin.vue'
import DashBoard from '@/views/admin/DashBoard.vue'
import TenderDetails from '@/views/admin/TenderDetails.vue'
import store from "@/store/index.js"
import { isPermission } from '@/utility/common.js'

function check(to, next, allPermission) {
    if (to.meta.middleware) {
        if (isPermission(allPermission, to.meta.middleware) && allPermission.length > 0) {
            next();
        } else {
            next({ name: 'Dashboard' });
        }
    } else {
        next();
    }
}
function guardPermission(to, from, next) {
    let allPermission = store.getters["permissions/listWithoutPagination"];
    if (allPermission == null) {
        if (store.getters['auth/isAuthenticated']) {
            store.dispatch('permissions/fetchWithoutPagiantionList').then(() => {
                let allPermission = store.getters["permissions/listWithoutPagination"];
                check(to, next, allPermission);
            });
        }
    } else {
        check(to, next, allPermission);
    }
}

const routes = [
    {
        path: '/',
        name: 'login',
        component: UserLogin,
        meta: {
            title: 'Login',
            layout: 'PublicLayout',
        }
    },
    {
        path: '/forget-password',
        name: 'forget_password',
        component: () => import('@/views/user/ForgetPassword.vue'),
        meta: {
            title: 'Forget Password',
            layout: 'PublicLayout',
        }
    },
    {
        path: '/verify-password',
        name: 'verify_password',
        component: () => import('@/views/user/VerifyPassword.vue'),
        meta: {
            title: 'Verify Password',
            layout: 'PublicLayout',
        }
    },
    {
        path: '/admin/dashboard',
        name: 'Dashboard',
        beforeEnter: guardPermission,
        component: DashBoard,
        meta: {
            title: 'Dashboard',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/lot',
        name: 'lot',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/lot/ItemList.vue'),
        meta: {
            title: 'Lot',
            layout: 'AdminLayout',
            middleware: 'lot.index'
        }
    },
    {
        path: '/admin/general-settings/lot/create',
        name: 'lot_create',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/lot/ItemCreate.vue'),
        meta: {
            title: 'Lot create',
            layout: 'AdminLayout',
            middleware: 'lot.store',
        }
    },
    {
        path: '/admin/general-settings/lot/edit/:id',
        name: 'lot_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/lot/ItemEdit.vue'),
        meta: {
            title: 'Lot Edit',
            layout: 'AdminLayout',
            middleware: 'lot.update',
        }
    },
    {
        path: '/admin/general-settings/jv-partner',
        name: 'jv_partner',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/jv_partner/list/JVPartnerList.vue'),
        meta: {
            title: 'JV Partner',
            layout: 'AdminLayout',
            middleware: 'jv_partners.index'
        }
    },
    {
        path: '/admin/general-settings/jv-partner/create',
        name: 'jv_partner_create',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/jv_partner/create/JVPartnerCreate.vue'),
        meta: {
            title: 'JV Partner create',
            layout: 'AdminLayout',
            middleware: 'jv_partners.store',
        }
    },
    {
        path: '/admin/general-settings/jv-partner/edit/:id',
        name: 'jv_partner_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/jv_partner/edit/JVPartnerEdit.vue'),
        meta: {
            title: 'JV Partner Edit',
            layout: 'AdminLayout',
            middleware: 'jv_partners.update',
        }
    },
    {
        path: '/admin/general-settings/currency',
        name: 'currency',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/currency/list/currencyList.vue'),
        meta: {
            title: 'Currency',
            layout: 'AdminLayout',
            middleware: 'currency.index'
        }
    },
    {
        path: '/admin/general-settings/currency/create',
        name: 'currency_create',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/currency/create/currencyCreate.vue'),
        meta: {
            title: 'Currency create',
            layout: 'AdminLayout',
            middleware: 'currency.store',
        }
    },
    {
        path: '/admin/general-settings/currency/edit/:id',
        name: 'currency_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/currency/edit/currencyEdit.vue'),
        meta: {
            title: 'Currency Edit',
            layout: 'AdminLayout',
            middleware: 'currency.update',
        }
    },
    {
        path: '/admin/general-settings/concern',
        name: 'concern',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/concern/list/ConcernList.vue'),
        meta: {
            title: 'Concern',
            layout: 'AdminLayout',
            middleware: 'concern.index'
        }
    },
    {
        path: '/admin/general-settings/concern/create',
        name: 'concern_create',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/concern/create/ConcernCreate.vue'),
        meta: {
            title: 'Concern create',
            layout: 'AdminLayout',
            middleware: 'concern.store',
        }
    },
    {
        path: '/admin/general-settings/concern/edit/:id',
        name: 'concern_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/concern/edit/ConcernEdit.vue'),
        meta: {
            title: 'Concern Edit',
            layout: 'AdminLayout',
            middleware: 'concern.update',
        }
    },
    {
        path: '/admin/general-settings/made-of-payment',
        name: 'made_of_payment',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/made-of-payment/ItemList.vue'),
        meta: {
            title: 'Made of payment',
            layout: 'AdminLayout',
            middleware: 'made_of_payment.index'
        }
    },
    {
        path: '/admin/general-settings/made-of-payment/create',
        name: 'made_of_payment_create',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/made-of-payment/ItemCreate.vue'),
        meta: {
            title: 'Made of payment create',
            layout: 'AdminLayout',
            middleware: 'made_of_payment.store',
        }
    },
    {
        path: '/admin/general-settings/made-of-payment/edit/:id',
        name: 'made_of_payment_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/made-of-payment/ItemEdit.vue'),
        meta: {
            title: 'Made of payment edit',
            layout: 'AdminLayout',
            middleware: 'made_of_payment.update',
        }
    },
    {
        path: '/admin/general-settings/bank',
        name: 'bank',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/bank/ItemList.vue'),
        meta: {
            title: 'Bank',
            layout: 'AdminLayout',
            middleware: 'bank.index',
        }
    },
    {
        path: '/admin/general-settings/bank/create',
        name: 'bank_create',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/bank/ItemCreate.vue'),
        meta: {
            title: 'Bank Create',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/bank/edit/:id',
        name: 'bank_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/bank/ItemEdit.vue'),
        meta: {
            title: 'Bank Edit',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/department',
        name: 'department',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/department/ItemList.vue'),
        meta: {
            title: 'Department',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/department/create',
        name: 'department_create',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/department/ItemCreate.vue'),
        meta: {
            title: 'Department create',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/department/edit/:id',
        name: 'department_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/department/ItemEdit.vue'),
        meta: {
            title: 'Department edit',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/procuring',
        name: 'procuring',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/procuring/ItemList.vue'),
        meta: {
            title: 'Procuring',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/procuring/create',
        name: 'procuring_create',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/procuring/ItemCreate.vue'),
        meta: {
            title: 'Procuring create',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/procuring/edit/:id',
        name: 'procuring_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/procuring/ItemEdit.vue'),
        meta: {
            title: 'Procuring edit',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/procuring-method',
        name: 'procuring_method',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/procuring-method/ItemList.vue'),
        meta: {
            title: 'Procuring method',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/procuring-method/create',
        name: 'procuring_method_create',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/procuring-method/ItemCreate.vue'),
        meta: {
            title: 'Procuring method create',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/procuring-method/edit/:id',
        name: 'procuring_method_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/procuring-method/ItemEdit.vue'),
        meta: {
            title: 'Procuring method edit',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/project',
        name: 'project',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/project-name/ItemList.vue'),
        meta: {
            title: 'Project',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/project/create',
        name: 'project_create',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/project-name/ItemCreate.vue'),
        meta: {
            title: 'Project create',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/project/edit/:id',
        name: 'project_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/project-name/ItemEdit.vue'),
        meta: {
            title: 'Project edit',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/tender-type',
        name: 'tender_type',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/tender-type/ItemList.vue'),
        meta: {
            title: 'Tender type',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/tender-type/create',
        name: 'tender_type_create',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/tender-type/ItemCreate.vue'),
        meta: {
            title: 'Tender type create',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/tender-type/edit/:id',
        name: 'tender_type_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/tender-type/ItemEdit.vue'),
        meta: {
            title: 'Tender type edit',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/validity',
        name: 'validity',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/validity/ItemList.vue'),
        meta: {
            title: 'Validity',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/validity/create',
        name: 'validity_create',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/validity/ItemCreate.vue'),
        meta: {
            title: 'Validity create',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/general-settings/validity/edit/:id',
        name: 'validity_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/general-settings/validity/ItemEdit.vue'),
        meta: {
            title: 'Validity edit',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/add-tender-info',
        name: 'AddTenderInfo',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/TenderInfo/AddTenderInfo.vue'),
        meta: {
            title: 'Add tender info create',
            layout: 'AdminLayout',
            middleware: 'tender_information.store'
        }
    },
    {
        path: '/admin/tender-list',
        name: 'tenderList',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/TenderInfo/TenderList.vue'),
        meta: {
            title: 'Add tender info',
            layout: 'AdminLayout',
            middleware: 'tender_information.index'
        }
    },
    {
        path: '/admin/tender-list/edit/:id',
        name: 'tender_list_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/TenderInfo/TenderItemEdit.vue'),
        meta: {
            title: 'Add tender info edit',
            layout: 'AdminLayout',
            middleware: 'tender_information.update'
        }
    },
    {
        path: '/admin/tender-list/view/:id',
        name: 'tender_list_single',
        beforeEnter: guardPermission,
        component: () => import('@/components/add-tender/tender-info/SingleView.vue'),
        meta: {
            title: 'Tender Information',
            layout: 'AdminLayout',
            middleware: 'tender_information.show'
        }
    },
    {
        path: '/admin/tender-list/upcoming',
        name: 'tender_list_upcoming',
        beforeEnter: guardPermission,
        component: () => import('@/components/add-tender/tender-info/UpcommingTender.vue'),
        meta: {
            title: 'Upcoming',
            layout: 'AdminLayout',
            middleware: 'tender_information.index'
        }
    },
    {
        path: '/admin/tender-submission/list',
        name: 'list_tender_submission',
        beforeEnter: guardPermission,
        component: () => import('@/components/add-tender/tender-submission/TenderSubmissionList.vue'),
        meta: {
            title: 'Tender submission',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/tender-submission',
        name: 'add_tender_submission',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/TenderInfo/TenderSubmission.vue'),
        meta: {
            title: 'Tender submission create',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/tender-submission/edit/:id',
        name: 'edit_tender_submission',
        beforeEnter: guardPermission,
        component: () => import('@/components/add-tender/tender-submission/TenderSubmissionEdit.vue'),
        meta: {
            title: 'Tender submission edit',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/bill/bill-submission/list',
        name: 'bill_submission_list',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/bills/bill-submission/list/BillSubmissionList'),
        meta: {
            title: 'Bill submission',
            layout: 'AdminLayout',
            middleware: 'bill_submission.index'
        }
    },
    {
        path: '/admin/bill/bill-submission',
        name: 'bill_submission_add',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/bills/bill-submission/create/BillSubmissionCreate'),
        meta: {
            title: 'Bill submission create',
            layout: 'AdminLayout',
            middleware: 'bill_submission.store'
        }
    },
    {
        path: '/admin/bill/bill-submission/edit/:id',
        name: 'bill_submission_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/bills/bill-submission/edit/BillSubmissionEdit'),
        meta: {
            title: 'Bill submission edit',
            layout: 'AdminLayout',
            middleware: 'bill_submission.update'
        }
    },
    // Bill receiveable
    {
        path: '/admin/bill/bill-receive/list',
        name: 'bill_receive_list',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/bills/bill-receive/list/BillReceiveList'),
        meta: {
            title: 'Bill receive',
            layout: 'AdminLayout',
            middleware: 'bill_receive.index'
        }
    },
    {
        path: '/admin/bill/bill-receive',
        name: 'bill_receive_add',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/bills/bill-receive/create/BillReceiveCreate'),
        meta: {
            title: 'Bill receive create',
            layout: 'AdminLayout',
            middleware: 'bill_receive.store'
        }
    },
    {
        path: '/admin/bill/bill-receive/edit/:id',
        name: 'bill_receive_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/bills/bill-receive/edit/BillReceiveEdit'),
        meta: {
            title: 'Bill receive edit',
            layout: 'AdminLayout',
            middleware: 'bill_receive.update'
        }
    },

    {
        path: '/admin/award-and-execution/list',
        name: 'list_award_and_execution',
        beforeEnter: guardPermission,
        component: () => import('@/components/add-tender/award-and-execution/AwardAndExecutionList.vue'),
        meta: {
            title: 'Tender submission',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/award-and-execution',
        name: 'add_award_and_execution',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/TenderInfo/AddAwardAndExecution.vue'),
        meta: {
            title: 'Award and execution',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/award-and-execution/edit/:id',
        name: 'edit_award_and_execution',
        beforeEnter: guardPermission,
        component: () => import('@/components/add-tender/award-and-execution/AwardAndExecutionEdit.vue'),
        meta: {
            title: 'Award and execution',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/tender-detail',
        name: 'TenderDetails',
        beforeEnter: guardPermission,
        component: TenderDetails,
        meta: {
            title: 'Tender details',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/document-library',
        name: 'DocumentLibrary',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Document/DocumentLibrary.vue'),
        meta: {
            title: 'Document library',
            layout: 'AdminLayout',
            middleware: 'folders.index'
        }
    },
    {
        path: '/admin/report/contact-award',
        name: 'contact_award',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Report/contact-award/ContactAwardList.vue'),
        meta: {
            title: 'Contract award report',
            layout: 'AdminLayout',
            middleware: 'contact_award.report'
        }
    },
    {
        path: '/admin/report/failure',
        name: 'failure',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Report/failure/FailureList.vue'),
        meta: {
            title: 'Failure report',
            layout: 'AdminLayout',
            middleware: 'failure.report'
        }
    },
    {
        path: '/admin/reports',
        name: 'reports',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Report/AllReport.vue'),
        meta: {
            title: 'Reports',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/tender-details/:id',
        name: 'single_details',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Report/tender-details/TenderDetails.vue'),
        meta: {
            title: 'Tender Details',
        }
    },
    {
        path: '/admin/account-receivable',
        name: 'AccountReceivable',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Account/AccountReceiveAble.vue'),
        meta: {
            title: 'Account receivable',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/performance-security',
        name: 'PerformanceSecurity',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Approval/PerformanceSecurity.vue'),
        meta: {
            title: 'Performance security',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/approval/tender-list',
        name: 'approval_tender_list',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Approval/TenderInfoList.vue'),
        meta: {
            title: 'Approval tender list',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/approval/bill-receive',
        name: 'approval_bill_receive',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Approval/bill-receive/BillReceive.vue'),
        meta: {
            title: 'Bill receive list',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/approval/security-receival',
        name: 'approval_security_receival',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Approval/security-receival/SecurityReceiveApprovalList.vue'),
        meta: {
            title: 'Security receival',
            layout: 'AdminLayout',
        }
    }, 
    {
        path: '/admin/approval/performance-security-receival',
        name: 'performance_security_receival',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Approval/performance-security/PerformanceSecurityApprovalList.vue'),
        meta: {
            title: 'Performance security',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/approval/bank-guarantee-receival',
        name: 'bank_guarantee_receival',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Approval/bank-guarantee-receival/BankGuaranteeApprovalList.vue'),
        meta: {
            title: 'bank guarantee receival',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/retention-money',
        name: 'retention_money',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Approval/RetentionMoney.vue'),
        meta: {
            title: 'Retention money',
            layout: 'AdminLayout',
            middleware: 'retention_money_list.report'
        }
    },
    {
        path: '/admin/security-money',
        name: 'security_money',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Report/security-money/SecurityMoneyList.vue'),
        meta: {
            title: 'Security money',
            layout: 'AdminLayout',
            middleware: 'security_money.report'
        }
    },
    {
        path: '/admin/user-activity-log',
        name: 'UserActivityLog',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/user-activity-log/UserActivityLog.vue'),
        meta: {
            title: 'User activity log',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/edit-profile',
        name: 'EditProfile',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Settings/EditProfile.vue'),
        meta: {
            title: 'Edit profile',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/general-settings',
        name: 'GeneralSetting',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Settings/GeneralSetting.vue'),
        meta: {
            title: 'General settings',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/role/create',
        name: 'role_create',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Role/ItemCreate.vue'),
        meta: {
            title: 'Role create',
            layout: 'AdminLayout',
            middleware: 'roles.store'
        }
    },
    {
        path: '/admin/role/list',
        name: 'role_list',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Role/ItemList.vue'),
        meta: {
            title: 'Role',
            layout: 'AdminLayout',
            middleware: 'roles.index'
        }
    },
    {
        path: '/admin/role/:id',
        name: 'role_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/Role/ItemEdit.vue'),
        meta: {
            title: 'Role edit',
            layout: 'AdminLayout',
            middleware: 'roles.update'
        }
    },
    {
        path: '/profile',
        name: 'userProfile',
        component: () => import('@/components/user/UserProfile.vue'),
        meta: {
            title: 'Profile',
            layout: 'AdminLayout',
        }
    },
    {
        path: '/admin/user/list',
        name: 'user_list',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/user/ItemList.vue'),
        meta: {
            title: 'User',
            layout: 'AdminLayout',
            middleware: 'users.index'
        }
    },
    {
        path: '/admin/backup',
        name: 'backup',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/backup/BackUp.vue'),
        meta: {
            title: 'Backup',
            layout: 'AdminLayout',
            middleware: 'db_backup'
        }
    },
    {
        path: '/admin/user/create',
        name: 'user_create',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/user/ItemCreate.vue'),
        meta: {
            title: 'User create',
            layout: 'AdminLayout',
            middleware: 'users.store',
        }
    },
    {
        path: '/admin/user/edit/:id',
        name: 'user_edit',
        beforeEnter: guardPermission,
        component: () => import('@/views/admin/user/ItemEdit.vue'),
        meta: {
            title: 'User edit',
            layout: 'AdminLayout',
            middleware: 'users.update',
        }
    },
    {
        path: "/:pathMatch(.*)*",
        name: 'not_found',
        component: () => import("@/views/NotFound.vue"),
        meta: {
            title: 'Not Found',
        }

    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

// configure web meta description
router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    // authentication start
    if (
        to.name == 'forget_password'
        || to.name == 'verify_password'
    ) {
        next();
    } else if (!store.getters["auth/isAuthenticated"] && to.name != 'login') {
        next({ name: 'login' });
    } else if (store.getters["auth/isAuthenticated"] && to.name == 'login') {
        next({ name: 'Dashboard' });
    } else {
        next();
    }
});


export default router
