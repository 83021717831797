import instanceAxios from '@/utility/axios.js'

// initial state
const state = () => ({
    items: [],
    common: null,
    paginatedData: null,
    details: null,
    isLoading: false,
    isCreating: false,
    isUpdating: false,

    errorMessage: '',
    errors: {},
})

// getters
const getters = {
    list: state => state.items,
    common: state => state.common,
    paginatedData: state => state.paginatedData,
    details: state => state.details,
    isLoading: state => state.isLoading,
    isCreating: state => state.isCreating,
    isUpdating: state => state.isUpdating,
    errorMessage: state => state.errorMessage,
    errors: state => state.errors,
};

// mutations
const mutations = {
    SET_ITEMS: (state, items) => {
        state.items = items
    },
    SET_COMMON: (state, common) => {
        state.common = common
    },
    SET_PAGINATED: (state, paginatedData) => {
        state.paginatedData = paginatedData
    },
    SET_DETAIL: (state, details) => {
        state.details = details
    },
    IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    SET_CREATING(state, isCreated) {
        state.isCreating = isCreated;
    },
    SET_UPDATING(state, isUpdated) {
        state.isUpdating = isUpdated;
    },
    SET_ERROR_MSG(state, errorMsg) {
        state.errorMessage = errorMsg;
    },
    SET_ERRORS(state, error) {
        state.errors = error;
    },
    SET_NULL_ERRORS(state, error) {
        state.errors = error;
    }
}

// actions
const actions = {
    fetchList({ commit }, query = null) {
        let page = '';
        let search = '';
        let pageLength = 10;
        if (query !== null) {
            page = query.page;
            search = query.search;
            pageLength = query.pageLength;
        }
        commit('IS_LOADING', true);
        let url = `settings/concern?pageLength=${pageLength}`;
        if (search === null || search === "") {
            url = `${url}&page=${page}`;
        }
        else {
            url = `${url}&search=${search}`
        }
        instanceAxios.get(url)
            .then(res => {

                const items = res.data.data;
                commit('SET_ITEMS', items);
                const pagination = {
                    total: res.data.total,  // total number of elements or items
                    per_page: res.data.per_page, // items per page
                    current_page: res.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                    total_pages: res.data.last_page // total pages in record
                }
                commit('SET_PAGINATED', pagination);
                commit('IS_LOADING', false);

            }).catch(err => {
                console.log('error', err);
                commit('IS_LOADING', false);
            });
    },
    fetchDetail({ commit }, id) {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get(`settings/concern/${id}`)
                .then(res => {
                    commit('SET_DETAIL', res.data.payload.data);
                    commit('IS_LOADING', false);
                    resolve(200);
                }).catch(err => {
                    commit('IS_LOADING', false);
                    console.log('error', err);
                    reject(422);
                });
        });
    },
    storeItem({ commit }, details) {
        commit('SET_CREATING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post(`settings/concern`, details)
                .then(() => {
                    commit('SET_CREATING', false);
                    resolve(200);
                }).catch(err => {
                    console.log('error', err);
                    // Only validation errors
                    if (err.response.status === 422) {
                        commit('SET_ERRORS', err.response.data);
                    }
                    reject(422);
                    commit('SET_CREATING', false);
                });
        });

    },
    updateItem({ commit }, details) {
        commit('SET_UPDATING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.post(`settings/concern/${details.id}`, details)
                .then(() => {
                    commit('SET_UPDATING', false);
                    resolve(200);
                }).catch(err => {
                    commit('SET_UPDATING', false);
                    // Only validation errors
                    if (err.response.status === 422) {
                        commit('SET_ERRORS', err.response.data);
                    }
                    reject(422);
                });
        });

    },
    deleteItem({ commit }, id) {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.delete(`settings/concern/${id}`)
                .then(() => {
                    commit('IS_LOADING', false);
                    resolve(200);
                }).catch((error) => {
                    commit('IS_LOADING', false);
                    reject(error.response);
                });
        });
    },
    updateStatus(_, data) {
        instanceAxios.post(`settings/concern/updateStatus/${data.get('id')}`, data)
            .then(() => {
            }).catch(err => {
                console.log('error', err);
            });
    },
    setErrorEmpty({ commit }) {
        commit('SET_NULL_ERRORS', {});
    },
    fetchCommon: ({ commit }) => {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            instanceAxios.get('settings/concerns/common-data').then(response => {
                if (response.status == 200) {
                    // set auth user state
                    commit('SET_COMMON', response.data.payload);
                    commit('IS_LOADING', false);
                    resolve(200);
                }
            }).catch((error) => {
                commit('IS_LOADING', false);
                reject(error.response);
            })
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}