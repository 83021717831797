import axios from "axios";

// api base url
const axiosDownload = axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

axiosDownload.interceptors.request.use((config) => {
    // config authorization header for all request
    let access_token = localStorage.getItem('access_token');
    config.headers.Authorization = access_token ? `Bearer ${access_token}` : '';
    config.headers.responseType = 'arraybuffer';
    config.headers.responseEncoding = 'binary';
    return config;
});

axiosDownload.interceptors.response.use((response) => {
    return response;
});

export default axiosDownload; // export axios axiosDownload to be imported in your app