import instanceAxios from '@/utility/axios.js'

// initial state
const state = () => ({
    isLoading: false,
    backup_progress: 0,
})

// getters
const getters = {
    isLoading: state => state.isLoading,
    backup_progress: state => state.backup_progress,
};

// mutations
const mutations = {
    IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    SET_BACKUP_PROGRESS: (state, backup_progress) => {
        state.backup_progress = backup_progress;
    },
}

// actions
const actions = {
    backup({ commit }) {
        commit('IS_LOADING', true);
        return new Promise(function (resolve, reject) {
            // const config = {
            //     onUploadProgress: function (progressEvent) {
            //         var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            //         commit("SET_BACKUP_PROGRESS", percentCompleted);
            //     }
            // };
            instanceAxios.get(`backup-db`)
                .then(() => {
                    commit('IS_LOADING', false);
                    commit("SET_BACKUP_PROGRESS", 0);
                    resolve(200);
                }).catch(() => {
                    commit('IS_LOADING', false);
                    commit("SET_BACKUP_PROGRESS", 0);
                    reject(422);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}