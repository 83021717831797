<template>
    <div>
        <div class="icon-box-area">
            <div class="row g-0">
                <div class="col-5">
                    <div class="icon-box-left">
                        <div class="icon-box-left-area">
                            <div class="icon-box-left-content" :style="{ 'background-color': backgroundColor }">
                                <img :src="icon" alt="left-content">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-7">
                    <div class="icon-box-right">
                        <div class="icon-box-right-content">
                            <h1 class="mb-2">{{ total }}</h1>
                            <p>{{ title }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {

        title: {
            type: String,
            default: "Total Tender",
        },
        total: {
            type: String,
            default: '500',
        },
        icon: {
            type: String,
            default: `${require("@/assets/img/icon/total-tender.png")}`,
        },
        backgroundColor: {
            type: String,
            default: "red",
        },
    },
};
</script>
<style lang="scss">
.icon-box-area {
    padding: 25px 0px 25px 10px;
    border: 1px solid rgba(216, 0, 115, 0.15);
    border-radius: 5px;
    box-shadow: 0px 0px 25px rgba(190, 190, 190, 0.25);
}

.icon-box-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-box-left-area {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid rgba(216, 0, 115, 0.15);

    .icon-box-left-content {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        margin-left: 0px;
        margin-top: 0px;
        transition: 0.2s;

        img {
            width: 100%;
            height: 100%;
            object-fit: none;
        }
    }
}

.icon-box-right {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    .icon-box-right-content {
        font-family: $ff_Inter;

        h1 {
            color: #5e5873;
            font-weight: bold;
            font-size: 20px;
            line-height: 30px;
        }

        p {
            color: #6e6b7b;
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            text-transform: capitalize;
        }
    }
}

// hover effect
.icon-box-area {
    &:hover {
        .icon-box-left-area {
            .icon-box-left-content {
                margin-left: -9px;
                margin-top: 7px;
            }
        }
    }
}

// 820 px
@media (min-width: 820px) {
    .icon-box-left-area {
        width: 80px;
        height: 80px;
    }

    .icon-box-right {
        .icon-box-right-content {
            font-family: $ff_Inter;

            h1 {
                font-size: 17px;
                line-height: 18px;
            }

            p {
                font-size: 16px;
                line-height: 18px;
            }
        }
    }
}

// 1024 px
@media (min-width: 1024px) {
    .icon-box-left-area {
        width: 80px;
        height: 80px;
    }

    .icon-box-right {
        .icon-box-right-content {
            h1 {
                font-size: 20px;
                line-height: 25px;
            }

            p {
                font-size: 16px;
                line-height: 18px;
            }
        }
    }

}
</style>