
import 'bootstrap/dist/css/bootstrap.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueProgressBar from "@aacassandra/vue3-progressbar"
import moment from 'moment';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import Toaster from '@meforma/vue-toaster';

import { isPermission, string_limit, getFilePath, string_last, download_csv } from "@/utility/common.js";

// progress bar options
const options = {
    color: "#02A0FC",
    failedColor: "#FF3A29",
    thickness: "3px",
    transition: {
        speed: "0.2s",
        opacity: "0.6s",
        termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
};

let app = createApp(App)

app.config.globalProperties.$globalVariable = 'Mahmud';
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$isPermission = isPermission;
app.config.globalProperties.$string_limit = string_limit;
app.config.globalProperties.$getFilePath = getFilePath;
app.config.globalProperties.$string_last = string_last;
app.config.globalProperties.$download_csv = download_csv;


app.use(VueSweetalert2)
app.use(Toaster, {
    position: "top-right",
});

// make v-focus usable in all components
app.directive('focus', {
    /* ... */
    mounted: (el) => el.focus()
})


app.use(store).use(router).use(VueProgressBar, options).mount('#app')


import 'bootstrap/dist/js/bootstrap.min.js'

export default app;
